
<template>
  <div class="App setting">
    <div v-if="isshowgrid">
      <va-card>
        <div class="row">
          <div class="flex xs12">
            <va-tabs grow v-model="tabValue" style="width: 400px;">
              <va-tab v-for="title in tabTitles" :key="title">
                {{title}}
              </va-tab>
            </va-tabs>
          </div>
        </div>
      </va-card>
      <va-card>
        <div class="row">
          <div class="flex md6">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 text-right">
            <va-button type="submit" icon="fa fa-plus" class="my-0" @click.prevent="add()">Create</va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="status" slot-scope="props">
            <va-button data-toggle="tooltip" title="Build Failed!" v-if="props.rowData.status === 'errored' || props.rowData.status === 'Error'" flat medium color="red" icon="fa fa-exclamation-circle" class="ma-0"></va-button>
            <va-button data-toggle="tooltip" title="Build Success!" v-if="props.rowData.status === 'finished'|| props.rowData.status === 'Completed'" flat medium color="green" icon="fa fa-check-circle-o" class="ma-0 cursor"></va-button>
            <va-button data-toggle="tooltip" title="Build in progress!" v-if="props.rowData.status === 'progress' || props.rowData.status === 'pending'" flat medium color="blue" icon="fa fa-spinner fa-pulse" class="ma-0"></va-button>
          </template>
          <template slot="edit" slot-scope="props">
            <va-button  flat small color="gray" icon="fa fa-pencil" @click="updateNow(props.rowData,'onEdit')" class="ma-0"></va-button>
          </template>
          <template slot="actions" slot-scope="props">
            <va-button flat small color="red" icon="fa fa-remove" v-if='!supportRole' @click="remove(props.rowData)" class="ma-0"></va-button>
          </template>
          <template slot="download" slot-scope="props">
            <va-button  flat small color="#fc8c03" icon="fa fa-download" @click="getApk(props.rowData.apk_url)" class="ma-0"></va-button>
          </template>
          <template slot="release_version" slot-scope="props">
            <div @click="updateTab(props.rowData)" class="versionCursor">{{ props.rowData.release_version}}</div>
          </template>
        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          :title="deleteTitle"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteApp(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if='isshowForm' class="mt-3">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">App Name</span>
            <va-input
              v-model.trim="app_name"
              v-if="isCreate"
              type="text"
              pattern="^[A-Za-z]+(?: +[A-Za-z]+)*$"
              placeholder="Enter App Name"
              :error="!!AppNameErrors.length"
              :error-messages="AppNameErrors"
            />
            <va-input
              v-if="isUpdate"
              v-model="app_name"
              type="text"
              disabled
              placeholder="Enter App Name"
            />
            <div>
              <span class="va-form-label va-form-required-label">Customer Organization Name</span>
              <va-select
                v-if="isCreate"
                v-model.trim="organization_name"
                textBy="org_name"
                :options="organization_nameArr"
                placeholder="Enter Organization Name"
                :error="!!organization_nameErrors.length"
                :error-messages="organization_nameErrors"
                searchable
              />
              <va-input
                v-if="isUpdate"
                v-model.trim="organization_name"
                type="text"
                disabled
              />
            </div>
            <span v-if="reseller_role" class="va-form-label va-form-required-label">Organization Name</span>
            <va-select
              v-if="reseller_role && isCreate"
              placeholder="Select Operator Organization"
              v-model="operator_org_id"
              textBy="org_name"
              @input="getSelectedOrg(operator_org_id)"
              :options="operator_org_obj"
              :error="!!operatorOrgInfoErrors.length"
              :error-messages="operatorOrgInfoErrors"
              noClear
            />
            <va-input
              v-if="reseller_role && isUpdate"
              v-model="org_name"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Package Name</span>
            <va-input
              v-model.trim="package_name"
              type="text"
              v-if="isCreate"
              pattern="^[A-Za-z.]*$"
              placeholder="Enter Package Name"
              :error="!!PackageNameErrors.length"
              :error-messages="PackageNameErrors"
            />
            <va-input
              v-model="package_name"
              v-if="isUpdate"
              type="text"
              disabled
              pattern="^[A-Za-z.]*$"
            />
            <span class="va-form-label va-form-required-label">Json Token</span>
            <va-input
              v-model="app_token"
              type="text"
              v-if="isCreate"
              placeholder="Enter Token"
              :error="!!AppTokenErrors.length"
              :error-messages="AppTokenErrors"
            />
            <va-input
              v-model="app_token"
              type="text"
              v-if="isUpdate"
            />
            <div class="row">
              <div class="flex md4">
                <span class="va-form-label va-form-required-label">Domain Type</span>
                <va-select
                  v-model="protocol"
                  placeholder="Select Domain Type"
                  :options="domainTypeOptions"
                  :error="!!DomainTypeErrors.length"
                  :error-messages="DomainTypeErrors"
                />
              </div>
              <div class="flex md3 ml-4 mr-4">
                <span class="va-form-label va-form-required-label">Enter Ip/Domain</span>
                <va-input
                  v-model="cdn_domain"
                  type="text"
                  placeholder="Enter Ip/Domain"
                  :error="!!CdnDomainErrors.length"
                  :error-messages="CdnDomainErrors"
                />
              </div>
              <div class="flex md4">
                <span class="va-form-label">Port</span>
                <va-input
                  v-model="port"
                  type="text"
                  pattern="[0-9]{6}"
                  maxlength="6"
                  placeholder="Enter Port"
                  :error="!!PortErrors.length"
                  :error-messages="PortErrors"
                />
              </div>
            </div>
            <div class="flex xs4 md2">
              <div class="vuestic-color-picker-page__top-square">
                <span
                  class="title no-wrap"
                  :style="{color: this.$themes.primary}"
                >
                  Brand Color
                </span>
                <va-color-picker-input
                  mode="advanced"
                  v-model="brand_color"
                  :error="!!ColourErrors.length"
                  :error-messages="ColourErrors"
                >
                  <va-color-square :value="brand_color"/>
                </va-color-picker-input>
              </div>
            </div>
            <br>
            <va-checkbox v-if="isUpdate" @input="Checkreupload()" v-model="reupolad" label="Click to reupload Images"/>
            <div class="flex xs12 mt-2">
              <div v-if="isCreate || isReupload">
                <span class="va-form-label va-form-required-label">Mobile (512px X 512px) PNG</span>
                <va-file-upload
                  type="single"
                  dropzone
                  @input="CheckFile('logo')"
                  v-model="logo_file"
                />
              </div>
            </div>
            <div class="flex xs12 mt-2">
              <div v-if="isCreate || isReupload">
                <span class="va-form-label va-form-required-label">TV (1280px X 720px) PNG</span>
                <va-file-upload
                  type="single"
                  dropzone
                  @input="CheckFile('banner')"
                  v-model="banner_file"
                />
              </div>
            </div>
            <div class="flex xs12 mt-2">
              <div v-if="isCreate || isReupload">
                <span class="va-form-label va-form-required-label">Banner (1024px X 500px) PNG</span>
                <va-file-upload
                  type="single"
                  dropzone
                  @input="CheckPlaystore('playstore_banner')"
                  v-model="playstore_banner"
                />
              </div>
            </div>
            <div class="flex xs12 mt-2">
              <div v-if="isCreate || isReupload">
                <span class="va-form-label va-form-required-label">Brand Logo (512px X 512px) PNG</span>
                <va-file-upload
                  type="single"
                  dropzone
                  @input="CheckPlaystore('brand')"
                  v-model="brand_logo"
                />
              </div>
            </div>
            <div class="flex xs12 mt-2">
              <div v-if="isCreate">
                <span class="va-form-label va-form-required-label">Json File</span>
                <va-file-upload
                  type="single"
                  dropzone
                  @input="Checkjson()"
                  v-model="json_file"
                />
              </div>
            </div>
            <va-checkbox v-if="isUpdate" @input="Checkgoogleplay()" v-model="play_auto_update" label="Auto Playstore Update" />
            <div class="flex xs12 mt-2">
              <div v-if="isUpdate">
                <div class="row">
                  <div class="flex xs4">
                    <span class="va-form-label">Version Code</span>
                    <va-input
                      v-model="app_version_code"
                      type="text"
      
                    />
                  </div>
                  <div class="flex xs4">
                    <span class="va-form-label">Version Name</span>
                    <va-input
                      v-model="app_version_name"
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs12">
              <div v-if="isUpdate || isPlayStore">
                <span class="va-form-label va-form-required-label">Account Name</span>
                <va-select
                  v-model="play_console_account"
                  placeholder="Select Account Type"
                  :options="playstoreTypeOptions"
                  :error="!!AccountErrors.length"
                  :error-messages="AccountErrors"
                />
              </div>
            </div>
            <div class="flex xs12">
              <div>
                <span class="va-form-label va-form-required-label">Code Type</span>
                <va-select
                  v-model="code_type"
                  placeholder="Select Code Type"
                  :options="code_type_option"
                  :error="!!codeTypeError.length"
                  error-messages="codeTypeError"
                  @input="codeType(code_type)"
                />
              </div>
            </div>
            <div class="flex xs12">
              <div v-if ="updatenow">
                <span class="va-form-label va-form-required-label">Select Version</span>
                <va-input
                  v-model="select_version"
                  type=text
                  disabled
                />
              </div>
              <div v-if ="(isUpdate || isCreate) && (!updatenow)">
                <span class="va-form-label va-form-required-label">Select Version</span>
                <va-select
                  v-model="select_version"
                  textBy="select_version"
                  :options="select_version_array"
                  :error="!!appSelectVersionErrors.length"
                  :error-message="appSelectVersionErrors"
                />
              </div>
            </div>
            <va-card>
              <span class="va-form-label va-form-required-label">Splash Screen</span>
              <fieldset class="row">
                <va-radio-button
                  option="None"
                  v-model="splash_screen"
                  label="None"
                />
                <va-radio-button
                  option="Video"
                  v-model="splash_screen"
                  label="Video"
                />
              </fieldset>
            </va-card>
            <div class="flex xs12">
              <va-card v-if="splash_screen == 'Video'">
                <div class="flex md12 sm12 xs12">
                  <input type="file" ref="tv_splash_video" style="display: none;" id="tv_splash_video" v-on:change="validateVideo($event,'tv')" accept=".mp4"><br>
                  <va-button type="submit" class="my-0" @click="$refs.tv_splash_video.click()">Upload TV Video</va-button>
                  <label>{{ tvVideoFileName }}</label>
                </div>
                <div class="flex md12 sm12 xs12">
                  <input type="file" ref="mobile_splash_video" style="display: none;" id="mobile_splash_video" v-on:change="validateVideo($event,'mobile')" accept=".mp4"><br>
                  <va-button type="submit" class="my-0" @click="$refs.mobile_splash_video.click()">Upload Mobile Video</va-button>
                  <label>{{ mobileVideoFileName }}</label>
                </div>
              </va-card>
            </div>
            <va-card>
              <span class="va-form-required-label va-form-label">App Dynamic Categories</span>
              <va-button
                icon="fa fa-plus"
                @click.prevent="dynamic()">
                Category
              </va-button>
            </va-card>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click="createApp()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateApps()">Build</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
    <div v-if="isShowLauncher">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <form>
          <div>
            <span class="va-form-label va-form-required-label">Operator Organization</span>
            <va-select
              v-if="isLauncherCreate"
              v-model.trim="organization_name"
              textBy="org_name"
              :options="organization_nameArr"
              placeholder="Enter Organization Name"
              :error="!!organization_nameErrors.length"
              :error-messages="organization_nameErrors"
              searchable
            />
            <va-input
              v-if="isLauncherUpdate"
              v-model.trim="organization_name"
              disabled
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">CDN URL</span>
            <va-input
              v-if="isLauncherCreate"
              v-model.trim="cdn_url"
              type="text"
              placeholder="Enter CDN URL"
              :error="!!cdn_urlErrors.length"
              :error-messages="cdn_urlErrors"
            />
            <va-input
              v-if="isLauncherUpdate"
              v-model.trim="cdn_url"
              type="text"
            />
          </div>
          <div>
            <div v-if="isLauncherCreate || isLauncherUpdate">
              <span class="va-form-label va-form-required-label">Time Zone</span>
              <va-select
                v-model.trim="time_zone"
                textBy="id"
                :options="time_zoneArr"
                placeholder="Choose Your Location"
                searchable
                :error="!!time_zoneErrors.length"
                :error-messages="time_zoneErrors"
              />
            </div>
            <!-- <div v-if="isLauncherUpdate">
              <span class="va-form-label">Time Zone</span>
              <va-input
                v-model.trim="time_zone"
                type="text"
                disabled
              />
            </div> -->
          </div>
          <!-- <div>
            <span class="va-form-label va-form-required-label">Operator Domain Prefix</span>
            <va-input
              v-if="isLauncherCreate"
              v-model.trim="operator_domain"
              type="text"
              placeholder="Enter Operator Domain"
              :error="!!operator_domainErrors.length"
              :error-messages="operator_domainErrors"
            />
            <va-input
              v-if="isLauncherUpdate"
              v-model.trim="operator_domain"
              type="text"
            />
          </div> -->
          <div>
            <span class="va-form-label va-form-required-label">Cloud Server URL</span>
            <va-input
              v-model="ott_url"
              type="text"
              v-if="isLauncherCreate || isLauncherUpdate"
              placeholder="Enter URL"
              :error="!!cloud_server_urlErrors.length"
              :error-messages="cloud_server_urlErrors"
            />
          </div>
          <div>
            <fieldset class="row ml-1 mt-1">
              <va-radio-button
                option="Image"
                v-model="launcher_logo"
                label="Image"
              />
              <va-radio-button
                option="Video"
                v-model="launcher_logo"
                label="Video"
              />
            </fieldset>
            <div>
              <div class="row">
                <div class="flex xs4">
                  <div class="mt-2">
                    <span class="va-form-label va-form-required-label" v-if="isLauncherCreate">Logo(512px X 512px)</span>
                    <span class="va-form-label" v-if="isLauncherUpdate">Re-Upload Logo(512px X 512px)</span>
                    <div class="row">
                      <va-file-upload
                        v-if="isLauncherCreate"
                        class="mt-2 ml-3"
                        type="single"
                        @input="CheckFile('logo')"
                        v-model="logo_file"
                      />
                      <div v-if="isLauncherUpdate">
                        <div class="scroll_overflow">
                          <div class="ml-4 mt-2" v-if="oldLogo">
                            <div>
                              <img :src="'data:image.png;base64,' + imgOne" alt="logo" height="80"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                      <va-file-upload
                        v-if="isLauncherUpdate"
                        class="mt-1 ml-3"
                        type="single"
                        @input="CheckFile('logo')"
                        v-model="logo_file"
                      />
                      <div 
                        v-if="isLauncherCreate || isLauncherUpdate"
                        class="imagePreviewWrapper"
                        :style="{ 'background-image': `url(${previewImage})` }">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex" v-if="launcher_logo == 'Image'">
                  <div class="mt-2">
                    <span class="va-form-label va-form-required-label" v-if="isLauncherCreate">Banner(1280px X 720px)</span>
                    <span class="va-form-label" v-if="isLauncherUpdate">Re-Upload Banner(1280px X 720px)</span>
                    <div class="row">
                      <va-file-upload
                        v-if="isLauncherCreate"
                        type="single"
                        class="mt-2 ml-3"
                        @input="CheckFile('banner')"
                        v-model="banner_file"
                      />
                      <div v-if="isLauncherUpdate">
                        <div class="scroll_overflow">
                          <div class="ml-4 mt-2" v-if="oldBanner">
                            <div><img :src="'data:image.png;base64,' + imgTwo" alt="banner" height="80"></div>                 
                          </div>
                        </div>
                      </div>
                      <va-file-upload
                        v-if="isLauncherUpdate"
                        type="single"
                        class="mt-1 ml-3"
                        @input="CheckFile('banner')"
                        v-model="banner_file"
                      />
                      <div
                        v-if="isLauncherCreate || isLauncherUpdate"
                        class="imagePreviewWrapper mt-1 ml-2"
                        :style="{ 'background-image': `url(${previewImage1})` }">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-if="launcher_logo == 'Video'">
                  <div class="flex">
                    <span class="va-form-label va-form-required-label">Upload Video</span>
                    <input
                      type="file"
                      style="display: none;"
                      ref="launcher_video"
                      accept=".mp4"
                      id="launcher_video"
                      class="mt-2"
                      v-on:change="validateVideo($event,'Launcher')"
                    />
                    <br>
                    <va-button
                      type="submit"
                      class="mt-1 mr-1"
                      @click.prevent="$refs.launcher_video.click()">
                      Upload Video
                    </va-button>
                    <div>
                      <label>{{ launcherVideoFileName }}</label>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
          <div class="flex xs12 mt-3">
            <div v-if="isLauncherCreate || isLauncherUpdate">
              <span class="va-form-required-label va-form-label">Type</span>
              <va-select
                v-model="launcher_type"
                placeholder="Select Your Type"
                :options="launcher_typeArr"
                :error="!!LauncherTypeErrors.length"
                :error-messages="LauncherTypeErrors"
                @input="onlauncher_version()"
              />
            </div>
          </div>
          <div class="flex xs12 mt-3">
            <div v-if ="launcherupdate">
              <span class="va-form-label va-form-required-label">Select Version</span>
              <va-input
                v-model="launcher_version"
                type=text
                disabled
              />
            </div>
            <div v-if ="(isLauncherUpdate || isLauncherCreate) && (!launcherupdate)" class="mt-3">
              <span class="va-form-label va-form-required-label">Select Version</span>
              <va-select
                v-model="launcher_version"
                textBy="launcher_version"
                :options="launcher_version_array"
                :error="!!launcher_versionErrors.length"
                :error-messages="launcher_versionErrors"
                placeholder="Select Your Version"
              />
            </div>
          </div>
          <!-- <div v-if="(isLauncherUpdate || isLauncherCreate) && (!launcherupdate)">
            <va-select
              v-model="launcher_version"
              :options="launcher_version_array"
              :error="!!launcher_versionErrors.length"
              :error-messages="launcher_versionErrors"
            />            
          </div> -->
          <!-- <div>
            <span class="va-form-label va-form-required-label">Video</span>
            <va-file-upload
              v-if="video"
              v-model="logo"
              class="mt-2"
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">Video(512px X 512px)</span>
            <va-file-upload
              v-if="video"
              v-model="banner_logo"
              class="mt-2"
            />
          </div>   -->
          <div class="flex xs12 md12">
            <span class="va-form-label va-form-required-label">Brand Color</span>
            <va-color-picker-input
              mode="advanced"
              v-model="brandColor"
              class="flex xs4"
              :error="brandColorErrors.length"
              :error-message="brandColorErrors"
            />
            <span class="va-form-label va-form-required-label">Secondar Color</span>
            <va-color-picker-input
              mode="advanced"
              v-model="secondary_color"
              class="flex xs4"
              :error="secondaryColorErrors.length"
              :error-message="secondaryColorErrors"
            />
            <span class="va-form-label va-form-required-label">Text Color</span>
            <va-color-picker-input
              mode="advanced"
              v-model="text_color"
              class="flex xs4"
              :error="textColorErrors.length"
              :error-message="textColorErrors"
            />
          </div>
          <div class="d-flex justify--end mt-3">
            <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
            <va-button
              v-if="isLauncherCreate"
              type="submit"
              class="my-0"
              @click.prevent="createLauncherApp()">
              Create
            </va-button>
            <va-button
              v-if="isLauncherUpdate"
              type="submit"
              class="my-0"
              @click.prevent="updateLauncherApp()">
              Build
            </va-button>
          </div>
        </form>
        <!-- <div>
          <va-input
            v-model.trim="organization_name"
            type="text"
            placeholder="Enter Organization Name"
            :error="!!organization_nameErrors.length"
            :error-messages="organization_nameErrors"
          />
        </div> -->
      </va-card>
    </div>
    <div v-if="isOnprem">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <!-- <span class="title-view" v-model="title_view">{{title}}</span>
        <va-button color="gray" class="flex offset--lg10" @click="list()">List</va-button> -->
        <div v-if="isOnpremCreate">
          <span class="va-form-label va-form-required-label">Platform</span>
          <va-select
            v-model.trim="platform"
            textBy="id"
            :options="platformArr"
            placeholder="Select Your Platform"
            :error="!!platformErrors.length"
            :error-messages="platformErrors"
            @input="onprem_version(platform)"
          />
        </div>
        <div v-if="isOnpremUpdate">
          <span>Platform</span>
          <va-input
            v-model.trim="platform"
            type="text"
            disabled
          />
        </div>
        <div v-if="platform == 'CDN'">
          <div v-if="isOnpremCreate">
            <span class="va-form-label va-form-required-label">Customer Organization Name</span>
            <va-select
              v-model.trim="organization_name"
              textBy="org_name"
              :options="organization_nameArr"
              placeholder="Select Organization Name"
              :error="!!organization_nameErrors.length"
              :error-messages="organization_nameErrors"
              searchable
            />
          </div>          
        </div>
        <div v-if="isOnpremUpdate && onPremPlatform">
          <span>Customer Organization Name</span>
          <va-input
            v-model.trim="organization_name"
            type="text"
            disabled
          />
        </div>
        <div v-if="platform == 'SMS' || platform =='DRM'">
          <div v-if="isOnpremCreate">
            <span class="va-form-required-label va-form-label">Provider Name</span>
            <va-select
              v-model.trim="provider_name"
              textBy="provider"
              :options="providerNameArr"
              placeholder="Select Provider Name"
              :error="!!ProviderNameErrors.length"
              :error-messages="ProviderNameErrors"
              searchable
            />
          </div>
        </div>
        <div v-if="isOnpremUpdate && platform_type">
          <span>Provider Name</span>
          <va-input
            v-model.trim="provider_name"
            type="text"
            disabled
          />
        </div>
        <div>
          <span class="va-form-label va-form-required-label">Access IP/Domain</span>
          <va-input
            v-if="isOnpremCreate"
            v-model.trim="ip_domain"
            placeholder="Enter Your IP"
            :error="!!ip_domainErrors.length"
            :error-messages="ip_domainErrors"
          />
          <va-input
            v-if="isOnpremUpdate"
            v-model.trim="ip_domain"
            type="text"	
	    :error="!!ip_domainErrors.length"
	    :error-messages="ip_domainErrors"
          />
        </div>
        <div>
          <span class="va-form-label va-form-required-label">Access Port</span>
          <va-input
            v-if="isOnpremCreate"
            v-model.trim="port"
            placeholder="Enter Your Port"
            :error="!!portErrors.length"
            :error-messages="portErrors"
          />
          <va-input
            v-if="isOnpremUpdate"
            v-model.trim="port"
            type="text"
	    :error="!!portErrors.length"
            :error-messages="portErrors"
          />
        </div>
        <div>
          <span class="va-form-label va-form-required-label">Access Token</span>
          <va-input
            v-if="isOnpremCreate"
            v-model.trim="token"
            placeholder="Enter Your Token"
            :error="!!tokenErrors.length"
            :error-messages="tokenErrors"
          />
          <va-input
            v-if="isOnpremUpdate"
            v-model.trim="token"
            type="text"
	    :error="!!tokenErrors.length"
            :error-messages="tokenErrors"
          />
        </div>
        <div class="flex xs12">
          <div v-if ="onprem_update">
            <span class="va-form-label va-form-required-label">Select Version</span>
            <va-input
              v-model="platform_version"
              type=text
            />
          </div>
          <div v-if ="(isOnpremCreate || isOnpremUpdate) && (!onprem_update)">
            <span class="va-form-label va-form-required-label">Select Version</span>
            <va-select
              v-model="platform_version"
              textBy="platform_version"
              :options="onperm_version"
              placeholder="Select Your Version"
              :error="OnpremVersionErrors.length"
              :error-message="OnpremVersionErrors"
	      searchable
            />
          </div>
        </div>
        <div class="d-flex justify--end mt-3">
          <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
          <va-button v-if="isOnpremCreate" type="submit" class="my-0" @click.prevent="createOnprem()">Create</va-button>
          <va-button v-if="isOnpremUpdate" type="submit" class="my-0" @click.prevent="updateOnprem()">Build</va-button>
        </div>
      </va-card>
    </div>

    <div v-if="isShowCloud">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <form>
          <div v-if="isCloudCreate">
            <span class="va-form-label va-form-required-label">Instance Name</span>
            <va-input
              v-model.trim="instance_name"
              :error="!!instance_nameErrors.length"
              :error-messages="instance_nameErrors"
              placeholder="Enter Your Instance Name"
            />
          </div>
          <div v-if="isCloudUpdate">
            <span>Instance Name</span>
            <va-input
              v-model.trim="instance_name"
              type="text"
              disabled
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">IP</span>
            <va-input
              v-if="isCloudCreate"
              v-model.trim="ip"
              :error="!!ipErrors.length"
              :error-messages="ipErrors"
              placeholder="Enter Your IP"
            />
            <va-input
              v-if="isCloudUpdate"
              v-model.trim="ip"
              type="text"
              :error="!!ipErrors.length"
              :error-messages="ipErrors"
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">Port</span>
            <va-input
              v-if="isCloudCreate"
              v-model.trim="cloud_port"
              :error="!!cloud_portErrors.length"
              :error-messages="cloud_portErrors"
              placeholder="Enter Your Port"
            />
            <va-input
              v-if="isCloudUpdate"
              v-model.trim="cloud_port"
              type="text"
              :error="!!cloud_portErrors.length"
              :error-messages="cloud_portErrors"
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">Token</span>
            <va-input
              v-if="isCloudCreate"
              v-model.trim="cloud_token"
              :error="!!cloud_tokenErrors.length"
              :error-messages="cloud_tokenErrors"
              placeholder="Enter Your Token"
            />
            <va-input
              v-if="isCloudUpdate"
              v-model.trim="cloud_token"
              type="text"
              :error="!!cloud_tokenErrors.length"
              :error-messages="cloud_tokenErrors"
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">Backup Infra Id</span>
            <va-input
              v-if="isCloudCreate"
              v-model.trim="backup_infra_id"
              :error="!!backup_infra_idErrors.length"
              :error-messages="backup_infra_idErrors"
              placeholder="Enter Your Backup-Infra-ID"
            />
            <va-input
              v-if="isCloudUpdate"
              v-model.trim="backup_infra_id"
              type="text"
              :error="!!backup_infra_idErrors.length"
              :error-messages="backup_infra_idErrors"
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">Backup DB Id</span>
            <va-input
              v-if="isCloudCreate"
              v-model.trim="backup_db_id"
              :error="!!backup_db_idErrors.length"
              :error-messages="backup_db_idErrors"
              placeholder="Enter Your Backup-DB_ID"
            />
            <va-input
              v-if="isCloudUpdate"
              v-model.trim="backup_db_id"
              type="text"
              :error="!!backup_db_idErrors.length"
              :error-messages="backup_db_idErrors"
            />
          </div>
          <div>
            <span class="va-form-label va-form-required-label">Platform</span>
            <va-select
              v-if="isCloudCreate"
              v-model.trim="platform"
              textBy="id"
              :options="cloud_platform_arr"
              :error="!!platformErrors.length"
              :error-messages="platformErrors"
              @input="oncloud_version(platform)"
              placeholder="Select Your Platform"
            />
            <va-input
              v-if="isCloudUpdate"
              v-model.trim="platform"
              type="text"
              disabled
            />
          </div>
          <div class="flex xs12">
            <div v-if ="cloud_update">
              <span class="va-form-label va-form-required-label">Select Version</span>
              <va-input
                v-model="cloud_version"
                type=text
                disabled
              />
            </div>
            <div v-if ="(isCloudCreate || isCloudUpdate) && (!cloud_update)">
              <span class="va-form-label va-form-required-label">Select Version</span>
              <va-select
                v-model="cloud_version"
                textBy="cloud_version"
                :options="cloud_version_array"
                placeholder="Select Your Version"
                :error="!!cloudVersionErrors.length"
                :error-message="cloudVersionErrors"
              />
            </div>
          </div>
          <div class="d-flex justify--end mt-3">
            <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
            <va-button v-if="isCloudCreate" type="submit" class="my-0" @click.prevent="createCloudApp()">Create</va-button>
            <va-button v-if="isCloudUpdate" type="submit" class="my-0" @click.prevent="updateCloudApp()">Build</va-button>
          </div>

        </form>
      </va-card>
    </div>

    <div class="flex xs12 md12">
      <va-modal
        class="flex xs12 md12"
        v-model="isShowList"
        size="medium"
        noEscDismiss
        noOutsideDismiss
        hide-default-actions
      >
        <div class="ml-3 mr-3">
          <span class="title">Updated</span>
          <va-button class="flex offset--lg10" icon="fa fa-times" @click="list()" />
          <div class="mt-2 row">
            <span class="flex xs5"><b>Release Version</b></span>
            <span align=right>: {{selectedRow.release_version}}</span>
          </div>
          <div class="mt-2 row">
            <span class="flex xs5"><b>Released Date</b></span>
            <span align=right>: {{selectedRow.release_date}}</span>
          </div>
          <div class="mt-2 row">
            <span class="flex xs5"><b>Release Priority</b></span>
            <span align=right>: {{selectedRow.release_priority}}</span>
          </div>
          <div class="mt-2 row">
            <span class="flex xs5"><b>Release Type</b></span>
            <span align=right>: {{selectedRow.tested_production_environment}}</span>
          </div>
          <div class="mt-2 row">
            <span class="flex xs5"><b>Release Notes</b></span>
            <span align=right>: {{selectedRow.release_notes}}</span>
          </div>
          <!-- <div class="mt-2">
            <span>Selected Version</span>
            <va-select class="mt-1" v-model="select_version"/>
          </div> -->
        </div>
        <div>
          <va-button @click="updateNow(selectedRow,'onLink')" class="flex xs3 offset--md3 offset--lg9 mt-3">Update Now</va-button>
        </div>
      </va-modal>
    </div>
    <div>
      <va-modal
        v-model="isShowDynamic"
        class="flex xs12 md12"
        :title="Dynamic"
        size='large'
        okText="Confirm"
        cancelText="Cancel"
        @ok="finish()"
        @cancel="cancel()"
        fixed-layout
        noEscDismiss
        noOutsideDismiss>
        <div class="mt-3">
          <span class="va-form-required-label"><b>Text Size Type</b></span>
          <va-select
            v-model="textsize_type"
            class="mt-1"
            placeholder="Select Text-size Type"
            textBy="id"
            @input="selectTextSize(textsize_type)"
            :options="textsize_type_arr"
            :error="!!textsize_typeErrors.length"
            :error-message="textsize_typeErrors"
          />
        </div>
        <div class="mt-2">
          <form>
            <div>
              <span><b>Tile Orientation</b></span>
            </div>
            <va-card>
              <div class="row">
                <div class="flex xs2">
                  <span class="va-form-required-label va-form-label">Live</span>
                  <va-select
                    v-model="live"
                    placeholder="Select any one"
                    textBy="id"
                    :options="liveArr"
                    :error="!!liveErrors.length"
                    :error-message="liveErrors"
                  />
                </div>
                <div class="ml-3 flex xs2">
                  <span class="va-form-label va-form-required-label">Web Series</span>
                  <va-select
                    v-model="web_series"
                    placeholder="Select any one"
                    textBy="id"
                    :options="web_seriesArr"
                    :error="!!web_seriesErrors.length"
                    :error-message="web_seriesErrors"
                  />
                </div>
                <div class="ml-3 flex xs2">
                  <span class="va-form-label va-form-required-label">TV Shows</span>
                  <va-select
                    v-model="tv_shows"
                    placeholder="Select any one"
                    textBy="id"
                    :options="tv_showsArr"
                    :error="!!tv_showsErrors.length"
                    :error-message="tv_showsErrors"
                  />
                </div>
                <div class="ml-3 flex xs2">
                  <span class="va-form-label va-form-required-label">Movies</span>
                  <va-select
                    v-model="movies"
                    placeholder="Select any one"
                    textBy="id"
                    :options="moviesArr"
                    :error="!!moviesErrors.length"
                    :error-message="moviesErrors"
                  />
                </div>
              </div>
            </va-card>
            <div class="mt-3">
              <span><b>Default Tile</b></span>
              <va-card>
                <div>
                  <span class="va-form-label va-form-required-label">Horizontal Margin</span>
                  <va-input
                    v-if="isMid"
                    v-model="horizontal_margin"
                    type="number"
                    min="2"
                    max="8"
                    :error="!!horizontal_marginErrors.length"
                    :error-messages="horizontal_marginErrors"
                  />
                  <va-input
                    v-if="isMin || isMax"
                    v-model="horizontal_margin"
                    type="number"
                    disabled
                    :error="!!horizontal_marginErrors.length"
                    :error-messages="horizontal_marginErrors"
                  />
                </div>
                <div>
                  <span class="va-form-label va-form-required-label">Corner Radius</span>
                  <va-input
                    v-if="isMid"
                    v-model="corner_radius"
                    type="number"
                    min="2"
                    max="10"
                    :error="!!corner_radiusErrors.length"
                    :error-messages="corner_radiusErrors"
                  />
                  <va-input
                    v-if="isMin || isMax"
                    v-model="corner_radius"
                    type="number"
                    disabled
                    :error="!!corner_radiusErrors.length"
                    :error-messages="corner_radiusErrors"
                  />
                </div>
              </va-card>
            </div>
            <div class="mt-3">
              <span><b>Tile Count</b></span>
              <va-card>
                <div>Mobile</div>
                <va-card>
                  <div class="row">
                    <div class="flex xs3">
                      <span class="va-form-label va-form-required-label">Horizontal</span>
                      <va-input
                        v-if="isMid"
                        v-model="mobile_horizontal"
                        type="number"
                        min="2"
                        max="4"
                        :error="!!mobile_horizontalErrors.length"
                        :error-messages="mobile_horizontalErrors"
                      />
                      <va-input
                        v-if="isMin || isMax"
                        v-model="mobile_horizontal"
                        type="number"
                        disabled
                        :error="!!mobile_horizontalErrors.length"
                        :error-messages="mobile_horizontalErrors"
                      />
                    </div>
                    <div class="flex xs3 ml-3">
                      <span class="va-form-label va-form-required-label">Vertical</span>
                      <va-input
                        v-if="isMid"
                        v-model="mobile_vertical"
                        type="number"
                        min="3"
                        max="5"
                        :error="!!mobile_verticalErrors.length"
                        :error-messages="mobile_verticalErrors"
                      />
                      <va-input
                        v-if="isMin || isMax"
                        v-model="mobile_vertical"
                        type="number"
                        disabled
                        :error="!!mobile_verticalErrors.length"
                        :error-messages="mobile_verticalErrors"
                      />
                    </div>
                  </div>
                </va-card>
                <div class="mt-2">TV</div>
                <va-card>
                  <div class="row">
                    <div class="flex xs3">
                      <span class="va-form-label va-form-required-label">Horizontal</span>
                      <va-input
                        v-if="isMid"
                        v-model="tv_horizontal"
                        type="number"
                        min="4"
                        max="6"
                        :error="!!tv_horizontalErrors.length"
                        :error-messages="tv_horizontalErrors"
                      />
                      <va-input
                        v-if="isMin || isMax"
                        v-model="tv_horizontal"
                        type="number"
                        disabled
                        :error="!!tv_horizontalErrors.length"
                        :error-messages="tv_horizontalErrors"
                      />
                    </div>
                    <div class="flex xs3 ml-3">
                      <span class="va-form-label va-form-required-label">Vertical</span>
                      <va-input
                        v-if="isMid"
                        v-model="tv_vertical"
                        type="number"
                        min="5"
                        max="7"
                        :error="!!tv_verticalErrors.length"
                        :error-messages="tv_verticalErrors"
                      />
                      <va-input
                        v-if="isMin || isMax"
                        v-model="tv_vertical"
                        type="number"
                        disabled
                        :error="!!tv_verticalErrors.length"
                        :error-messages="tv_verticalErrors"
                      />
                    </div>
                  </div>
                </va-card>
              </va-card>
            </div>
            <div class="mt-3">
              <span><b>Tile Style</b></span>
              <va-card>
                <fieldset class="row">
                  <va-radio-button
                    option="Border"
                    v-model="tile_style"
                    label="Border"
                  />
                  <va-radio-button
                    option="Zoom"
                    v-model="tile_style"
                    label="Zoom"
                  />
                </fieldset>
                <div v-if="tile_style == 'Border'" class="mt-2">
                  <span class="mt-2 va-form-required-label va-form-label">Border Size</span>
                  <va-input
                    v-if="isMid"
                    v-model="border_size"
                    type="number"
                    min="1"
                    max="3"
                    :error="!!border_sizeErrors.length"
                    :error-messages="border_sizeErrors"
                  />
                  <va-input
                    v-if="isMin || isMax"
                    v-model="border_size"
                    type="number"
                    disabled
                    :error="!!border_sizeErrors.length"
                    :error-messages="border_sizeErrors"
                  />
                </div>
                <div v-if="tile_style == 'Zoom'" class="mt-2">
                  <span class="mt-2 va-form-required-label va-form-label">Zoom Size</span>
                  <va-select
                    v-if="isMid"
                    v-model="zoom_size"
                    textBy="id"
                    placeholder="Select Size"
                    :options="zoom_sizeArr"
                    :error="!!zoom_sizeErrors.length"
                    :error-messages="zoom_sizeErrors"
                  />
                  <va-input
                    v-if="isMin || isMax"
                    v-model="zoom_size"
                    disabled
                    :options="zoom_sizeArr"
                    :error="!!zoom_sizeErrors.length"
                    :error-messages="zoom_sizeErrors"
                  />
                </div>
              </va-card>
            </div>
            <div class="mt-3">
              <span><b>Banner Height</b></span>
              <va-card>
                <div>
                  <span class="va-form-required-label va-form-label">Mobile</span>
                  <va-input
                    v-if="isMid"
                    v-model="mobile_banner_height"
                    type="number"
                    min="190"
                    max="300"
                    :error="!!mobile_banner_heightErrors.length"
                    :error-messages="mobile_banner_heightErrors"
                  />
                  <va-input
                    v-if="isMin || isMax"
                    v-model="mobile_banner_height"
                    type="number"
                    disabled
                    :error="!!mobile_banner_heightErrors.length"
                    :error-messages="mobile_banner_heightErrors"
                  />
                  <span class="va-form-required-label va-form-label">TV</span>
                  <va-input
                    v-if="isMid"
                    v-model="tv_banner_height"
                    type="number"
                    min="300"
                    max="720"
                    :error="!!tv_banner_heightErrors.length"
                    :error-messages="tv_banner_heightErrors"
                  />
                  <va-input
                    v-if="isMin || isMax"
                    v-model="tv_banner_height"
                    type="number"
                    disabled
                    :error="!!tv_banner_heightErrors.length"
                    :error-messages="tv_banner_heightErrors"
                  />
                </div>
              </va-card>
            </div>
            <div class="mt-3">
              <span><b>Advertisement Height</b></span>
              <va-card>
                <div>
                  <span class="va-form-required-label va-form-label">Mobile</span>
                  <va-input
                    v-if="isMid"
                    v-model="mobile_advertisement_height"
                    type="number"
                    min="150"
                    max="170"
                    :error="!!mobile_advertisement_heightErrors"
                    :error-messages="mobile_advertisement_heightErrors"
                  />
                  <va-input
                    v-if="isMin || isMax"
                    v-model="mobile_advertisement_height"
                    type="number"
                    disabled
                    :error="!!mobile_advertisement_heightErrors"
                    :error-messages="mobile_advertisement_heightErrors"
                  />
                </div>
              </va-card>
            </div>
          </form>
        </div>
      </va-modal>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)

export default {
  name: 'app_setting',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + '/org')
      .then(resp => {
        loader.hide()
        this.organization_nameArr = []
        this.organization_nameArr = resp.body.filter(function (value, count) {
          value.id = count
          if ((value.org_type == 'OPERATOR') || (value.org_type == 'HEPI_OPERATOR')) {
            return !resp.body.some(function (data) {
              return value.org_name === data.name
            })
          }
        })
        this.$http.get(config.menu.host + '/provider')
        .then(respo => {
          loader.hide()
          var nameArray = []
          respo.body.map(function (item) {
            nameArray.push(item.brand)
          })
          this.providerNameArr = nameArray
        },error => {
          loader.hide()
        })
        this.$http.get(config.menu.host + '/release/versions')
          .then(res => {
            loader.hide()

            this.versions_Arr = res.body
          }, error => {
            loader.hide()
          })
      }, error => {
        loader.hide()
      })
    this.$http.get(config.menu.host + '/release/Apps')
      .then(response => {
        loader.hide()
        this.app_list = response.body
        this.isshowgrid = true
        this.isshowForm = false
	var role = this.$cookies.get('userRole')
        if (role == 'SUPPORT') {
          this.supportRole = true
        }
        this.getAllTabs()
      }, error => {
        loader.hide()
      },
      )
  },

  data () {
    var role = this.$cookies.get('userRole')
    var dynmaic_tab = []
    if (role == 'ADMIN' || 'SUPPORT') {
      dynmaic_tab = ['Apps', 'Launcher','On Prem','Cloud']
    }
    return {
      dynamicConf: false,
      Dynamic: 'App Dynamic Customization',
      tabTitles: dynmaic_tab,
      tabValue: 0,
      title: '',
      deleteTitle: '',
      previewImage: null,
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      operators: [],
      app_name: '',
      package_name: '',
      protocol: '',
      play_console_account: '',
      splash_screen: 'None',
      cdn_domain: '',
      ott_url: '',
      cloud_server_urlErrors: [],
      reupolad: false,
      play_auto_update: false,
      onPremPlatform: false,
      port: '',
      brand_color: '',
      logo_name: '',
      code_type: '',
      banner_name: '',
      appSelectVersionErrors: [],
      mobileVideoFileName: '',
      launcherVideoFileName: '',
      tvVideoFileName: '',
      tv_splash_video: '',
      mobile_splash_video: '',
      operator_org_id: '',
      organization_id: '',
      User: [],
      org_name: '',
      logo_file: [],
      logo_fileErrors: [],
      json_file: [],
      app_token: '',
      AppNameErrors: [],
      AppTokenErrors: [],
      msg: '',
      splash_tv_file: [],
      splash_mobile_file: [],
      brand_logo: [],
      banner_file: [],
      banner_fileErrors: [],
      launcher_logo: 'Image',
      playstore_banner: [],
      code_type_option: ['Standard', 'Premium'],
      domainTypeOptions: ['http', 'https'],
      textsize_type: '',
      isMin: false,
      isMid: false,
      isMax: false,
      textsize_type_arr: ['MIN', 'MID', 'MAX'],
      textsize_typeErrors: [],
      live: '',
      liveErrors: [],
      liveArr: ['Vertical', 'Horizontal'],
      web_series: '',
      web_seriesErrors: [],
      web_seriesArr: ['Vertical', 'Horizontal'],
      tv_shows: '',
      tv_showsErrors: [],
      tv_showsArr: ['Vertical', 'Horizontal'],
      movies: '',
      moviesErrors: [],
      moviesArr: ['Vertical', 'Horizontal'],
      horizontal_margin: '',
      horizontal_marginErrors: [],
      corner_radius: '',
      corner_radiusErrors: [],
      mobile_horizontal: '',
      mobile_horizontalErrors: [],
      mobile_vertical: '',
      mobile_verticalErrors: [],
      tv_horizontal: '',
      tv_horizontalErrors: [],
      tv_vertical: '',
      tv_verticalErrors: [],
      tile_style: 'Border',
      border_size: '',
      border_sizeErrors: [],
      zoom_size: '',
      zoom_sizeErrors: [],
      zoom_sizeArr: ['XL', 'L', 'S', 'M'],
      mobile_banner_height: '',
      mobile_banner_heightErrors: [],
      tv_banner_height: '',
      tv_banner_heightErrors: [],
      mobile_advertisement_height: '',
      mobile_advertisement_heightErrors: [],
      playstoreTypeOptions: [],
      PackageNameErrors: [],
      operatorOrgInfoErrors: [],
      DomainTypeErrors: [],
      CdnDomainErrors: [],
      operator_org_obj: [],
      ColourErrors: [],
      PortErrors: [],
      isCreateApp: false,
      reseller_role: false,
      isshowgrid: true,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      updatenow: false,
      isReupload: false,
      isPlayStore: false,
      toastText: 'This toast is awesome!',
      toastDuration: 2500,
      toastIcon: 'fa-star-o',
      toastPosition: 'top-right',
      isToastFullWidth: false,
      showRemoveModal: false,
      isShowList: false,
      codeTypeError: [],
      // isShowList: false,
      release_version: '',
      release_notes: '',
      released_date: '',
      release_priority: '',
      production: '',
      select_version: '',
      select_version_array: [],
      launcher_version_array: [],
      launcher_version: '',
      launcher_versionErrors: [],
      onprem_latest_version: '',
      platform_version: '',
      onperm_version: [],
      cloud_version_array: [],
      cloud_version: '',
      cloudVersionErros: [],
      versions_Arr: {},
      selectedRow: {},
      isShowLauncher: false,
      launcher_type: '',
      launcher_typeArr: ['Hepi', 'IPTV+OTT','OTT'],
      LauncherTypeErrors: [],
      organization_name: '',
      organization_nameErrors: [],
      organization_name: '',
      organization_nameErrors: [],
      organization_nameArr: [],
      provider_name: '',
      providerNameArr: [],
      ProviderNameErrors: [],
      cdn_urlErrors: [],
      cdn_url: '',
      // operator_domainErrors: [],
      // operator_domain: '',
      image: true,
      video: false,
      launcher_video: '',
      logo: [],
      banner_logo: [],
      brandColor: '',
      secondary_color: '',
      text_color: '',
      isLauncherCreate: false,
      isLauncherUpdate: false,
      launcherupdate: false,
      title_view: '',
      cus_org_name: '',
      cus_org_nameErrors: [],
      ip_domain: '',
      ip_domainErrors: [],
      port: '',
      portErrors: [],
      token: '',
      tokenErrors: [],
      version: '',
      versionErrors: [],
      platform: '',
      platformArr: ['DRM', 'SMS', 'CDN'],
      cloud_platform_arr: ['Skie', 'Skie Player', 'OTA'],
      platformErrors: [],
      isOnprem: false,
      isOnpremCreate: false,
      isOnpremUpdate: false,
      onprem_update: false,
      instance_name: '',
      instance_nameErrors: [],
      ip: '',
      platform_type: false,
      ipErrors: [],
      cloud_port: '',
      cloud_portErrors: [],
      cloud_token: '',
      cloud_tokenErrors: [],
      backup_infra_id: '',
      backup_infra_idErrors: [],
      backup_db_id: '',
      backup_db_idErrors: [],
      cloud_version: '',
      cloud_versionErrors: [],
      cloudVersionErrors: [],
      cloud_latest_version: '',
      cloud_latest_versionErrors: [],
      cloud_platform: '',
      cloud_platformErrors: [],
      isCloudCreate: false,
      isCloudUpdate: false,
      isShowCloud: false,
      cloud_update: false,
      app_list: [],
      time_zone: '',
      time_zoneErrors: [],
      launcher_list: [],
      on_prem_list: [],
      cloud_list: [],
      brandColorErrors: [],
      secondaryColorErrors: [],
      textColorErrors: [],
      OnpremVersionErrors: [],
      isShowDynamic: false,
      app_version_code: '',
      app_version_name: '',
      imgOne: '',
      imgTwo: '',
      previewImage1: null,
      oldLogo: true,
      oldBanner: true,
      time_zoneArr: [
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Addis_Ababa',
        'Africa/Algiers',
        'Africa/Asmara',
        'Africa/Asmera',
        'Africa/Bamako',
        'Africa/Bangui',
        'Africa/Banjul',
        'Africa/Bissau',
        'Africa/Blantyre',
        'Africa/Brazzaville',
        'Africa/Bujumbura',
        'Africa/Cairo',
        'Africa/Casablanca',
        'Africa/Ceuta',
        'Africa/Conakry',
        'Africa/Dakar',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Douala',
        'Africa/El_Aaiun',
        'Africa/Freetown',
        'Africa/Gaborone',
        'Africa/Harare',
        'Africa/Johannesburg',
        'Africa/Juba',
        'Africa/Kampala',
        'Africa/Khartoum',
        'Africa/Kigali',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Africa/Lome',
        'Africa/Luanda',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Malabo',
        'Africa/Maputo',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Africa/Mogadishu',
        'Africa/Monrovia',
        'Africa/Nairobi',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Africa/Porto-Novo',
        'Africa/Sao_Tome',
        'Africa/Timbuktu',
        'Africa/Tripoli',
        'Africa/Tunis',
        'Africa/Windhoek',
        'America/Adak',
        'America/Anchorage',
        'America/Anguilla',
        'America/Antigua',
        'America/Araguaina',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Argentina/ComodRivadavia',
        'America/Argentina/Cordoba',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Argentina/Mendoza',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Aruba',
        'America/Asuncion',
        'America/Atikokan',
        'America/Atka',
        'America/Bahia',
        'America/Bahia_Banderas',
        'America/Barbados',
        'America/Belem',
        'America/Belize',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Bogota',
        'America/Boise',
        'America/Buenos_Aires',
        'America/Cambridge_Bay',
        'America/Campo_Grande',
        'America/Cancun',
        'America/Caracas',
        'America/Catamarca',
        'America/Cayenne',
        'America/Cayman',
        'America/Chicago',
        'America/Chihuahua',
        'America/Coral_Harbour',
        'America/Cordoba',
        'America/Costa_Rica',
        'America/Creston',
        'America/Cuiaba',
        'America/Curacao',
        'America/Danmarkshavn',
        'America/Dawson',
        'America/Dawson_Creek',
        'America/Denver',
        'America/Detroit',
        'America/Dominica',
        'America/Edmonton',
        'America/Eirunepe',
        'America/El_Salvador',
        'America/Ensenada',
        'America/Fort_Nelson',
        'America/Fort_Wayne',
        'America/Fortaleza',
        'America/Glace_Bay',
        'America/Godthab',
        'America/Goose_Bay',
        'America/Grand_Turk',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guatemala',
        'America/Guayaquil',
        'America/Guyana',
        'America/Halifax',
        'America/Havana',
        'America/Hermosillo',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indianapolis',
        'America/Inuvik',
        'America/Iqaluit',
        'America/Jamaica',
        'America/Jujuy',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Knox_IN',
        'America/Kralendijk',
        'America/La_Paz',
        'America/Lima',
        'America/Los_Angeles',
        'America/Louisville',
        'America/Lower_Princes',
        'America/Maceio',
        'America/Managua',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Mendoza',
        'America/Menominee',
        'America/Merida',
        'America/Metlakatla',
        'America/Mexico_City',
        'America/Miquelon',
        'America/Moncton',
        'America/Monterrey',
        'America/Montevideo',
        'America/Montreal',
        'America/Montserrat',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Nome',
        'America/Noronha',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Ojinaga',
        'America/Panama',
        'America/Pangnirtung',
        'America/Paramaribo',
        'America/Phoenix',
        'America/Port-au-Prince',
        'America/Port_of_Spain',
        'America/Porto_Acre',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Punta_Arenas',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Recife',
        'America/Regina',
        'America/Resolute',
        'America/Rio_Branco',
        'America/Rosario',
        'America/Santa_Isabel',
        'America/Santarem',
        'America/Santiago',
        'America/Santo_Domingo',
        'America/Sao_Paulo',
        'America/Scoresbysund',
        'America/Shiprock',
        'America/Sitka',
        'America/St_Barthelemy',
        'America/St_Johns',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Swift_Current',
        'America/Tegucigalpa',
        'America/Thule',
        'America/Thunder_Bay',
        'America/Tijuana',
        'America/Toronto',
        'America/Tortola',
        'America/Vancouver',
        'America/Virgin',
        'America/Whitehorse',
        'America/Winnipeg',
        'America/Yakutat',
        'America/Yellowknife',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville',
        'Antarctica/Macquarie',
        'Antarctica/Mawson',
        'Antarctica/McMurdo',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/South_Pole',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'Arctic/Longyearbyen',
        'Asia/Aden',
        'Asia/Almaty',
        'Asia/Amman',
        'Asia/Anadyr',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Ashkhabad',
        'Asia/Atyrau',
        'Asia/Baghdad',
        'Asia/Bahrain',
        'Asia/Baku',
        'Asia/Bangkok',
        'Asia/Barnaul',
        'Asia/Beirut',
        'Asia/Bishkek',
        'Asia/Brunei',
        'Asia/Calcutta',
        'Asia/Chita',
        'Asia/Choibalsan',
        'Asia/Chongqing',
        'Asia/Chungking',
        'Asia/Colombo',
        'Asia/Dacca',
        'Asia/Damascus',
        'Asia/Dhaka',
        'Asia/Dili',
        'Asia/Dubai',
        'Asia/Dushanbe',
        'Asia/Famagusta',
        'Asia/Gaza',
        'Asia/Harbin',
        'Asia/Hebron',
        'Asia/Ho_Chi_Minh',
        'Asia/Hong_Kong',
        'Asia/Hovd',
        'Asia/Irkutsk',
        'Asia/Istanbul',
        'Asia/Jakarta',
        'Asia/Jayapura',
        'Asia/Jerusalem',
        'Asia/Kabul',
        'Asia/Kamchatka',
        'Asia/Karachi',
        'Asia/Kashgar',
        'Asia/Kathmandu',
        'Asia/Katmandu',
        'Asia/Khandyga',
        'Asia/Kolkata',
        'Asia/Krasnoyarsk',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Kuwait',
        'Asia/Macao',
        'Asia/Macau',
        'Asia/Magadan',
        'Asia/Makassar',
        'Asia/Manila',
        'Asia/Muscat',
        'Asia/Nicosia',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Omsk',
        'Asia/Oral',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Pyongyang',
        'Asia/Qatar',
        'Asia/Qostanay',
        'Asia/Qyzylorda',
        'Asia/Rangoon',
        'Asia/Riyadh',
        'Asia/Saigon',
        'Asia/Sakhalin',
        'Asia/Samarkand',
        'Asia/Seoul',
        'Asia/Shanghai',
        'Asia/Singapore',
        'Asia/Srednekolymsk',
        'Asia/Taipei',
        'Asia/Tashkent',
        'Asia/Tbilisi',
        'Asia/Tehran',
        'Asia/Tel_Aviv',
        'Asia/Thimbu',
        'Asia/Thimphu',
        'Asia/Tokyo',
        'Asia/Tomsk',
        'Asia/Ujung_Pandang',
        'Asia/Ulaanbaatar',
        'Asia/Ulan_Bator',
        'Asia/Urumqi',
        'Asia/Ust-Nera',
        'Asia/Vientiane',
        'Asia/Vladivostok',
        'Asia/Yakutsk',
        'Asia/Yangon',
        'Asia/Yekaterinburg',
        'Asia/Yerevan',
        'Atlantic/Azores',
        'Atlantic/Bermuda',
        'Atlantic/Canary',
        'Atlantic/Cape_Verde',
        'Atlantic/Faeroe',
        'Atlantic/Faroe',
        'Atlantic/Jan_Mayen',
        'Atlantic/Madeira',
        'Atlantic/Reykjavik',
        'Atlantic/South_Georgia',
        'Atlantic/St_Helena',
        'Atlantic/Stanley',
        'Australia/ACT',
        'Australia/Adelaide',
        'Australia/Brisbane',
        'Australia/Broken_Hill',
        'Australia/Canberra',
        'Australia/Currie',
        'Australia/Darwin',
        'Australia/Eucla',
        'Australia/Hobart',
        'Australia/LHI',
        'Australia/Lindeman',
        'Australia/Lord_Howe',
        'Australia/Melbourne',
        'Australia/NSW',
        'Australia/North',
        'Australia/Perth',
        'Australia/Queensland',
        'Australia/South',
        'Australia/Sydney',
        'Australia/Tasmania',
        'Australia/Victoria',
        'Australia/West',
        'Australia/Yancowinna',
        'Brazil/Acre',
        'Brazil/DeNoronha',
        'Brazil/East',
        'Brazil/West',
        'CET',
        'CST6CDT',
        'Canada/Atlantic',
        'Canada/Central',
        'Canada/Eastern',
        'Canada/Mountain',
        'Canada/Newfoundland',
        'Canada/Pacific',
        'Canada/Saskatchewan',
        'Canada/Yukon',
        'Chile/Continental',
        'Chile/EasterIsland',
        'Cuba',
        'EET',
        'EST5EDT',
        'Egypt',
        'Eire',
        'Etc/GMT',
        'Etc/GMT+0',
        'Etc/GMT+1',
        'Etc/GMT+10',
        'Etc/GMT+11',
        'Etc/GMT+12',
        'Etc/GMT+2',
        'Etc/GMT+3',
        'Etc/GMT+4',
        'Etc/GMT+5',
        'Etc/GMT+6',
        'Etc/GMT+7',
        'Etc/GMT+8',
        'Etc/GMT+9',
        'Etc/GMT-0',
        'Etc/GMT-1',
        'Etc/GMT-10',
        'Etc/GMT-11',
        'Etc/GMT-12',
        'Etc/GMT-13',
        'Etc/GMT-14',
        'Etc/GMT-2',
        'Etc/GMT-3',
        'Etc/GMT-4',
        'Etc/GMT-5',
        'Etc/GMT-6',
        'Etc/GMT-7',
        'Etc/GMT-8',
        'Etc/GMT-9',
        'Etc/GMT0',
        'Etc/Greenwich',
        'Etc/UCT',
        'Etc/UTC',
        'Etc/Universal',
        'Etc/Zulu',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Astrakhan',
        'Europe/Athens',
        'Europe/Belfast',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Bratislava',
        'Europe/Brussels',
        'Europe/Bucharest',
        'Europe/Budapest',
        'Europe/Busingen',
        'Europe/Chisinau',
        'Europe/Copenhagen',
        'Europe/Dublin',
        'Europe/Gibraltar',
        'Europe/Guernsey',
        'Europe/Helsinki',
        'Europe/Isle_of_Man',
        'Europe/Istanbul',
        'Europe/Jersey',
        'Europe/Kaliningrad',
        'Europe/Kiev',
        'Europe/Kirov',
        'Europe/Lisbon',
        'Europe/Ljubljana',
        'Europe/London',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Europe/Malta',
        'Europe/Mariehamn',
        'Europe/Minsk',
        'Europe/Monaco',
        'Europe/Moscow',
        'Europe/Nicosia',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Podgorica',
        'Europe/Prague',
        'Europe/Riga',
        'Europe/Rome',
        'Europe/Samara',
        'Europe/San_Marino',
        'Europe/Sarajevo',
        'Europe/Saratov',
        'Europe/Simferopol',
        'Europe/Skopje',
        'Europe/Sofia',
        'Europe/Stockholm',
        'Europe/Tallinn',
        'Europe/Tirane',
        'Europe/Tiraspol',
        'Europe/Ulyanovsk',
        'Europe/Uzhgorod',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Vilnius',
        'Europe/Volgograd',
        'Europe/Warsaw',
        'Europe/Zagreb',
        'Europe/Zaporozhye',
        'Europe/Zurich',
        'GB',
        'GB-Eire',
        'GMT',
        'GMT0',
        'Greenwich',
        'Hongkong',
        'Iceland',
        'Indian/Antananarivo',
        'Indian/Chagos',
        'Indian/Christmas',
        'Indian/Cocos',
        'Indian/Comoro',
        'Indian/Kerguelen',
        'Indian/Mahe',
        'Indian/Maldives',
        'Indian/Mauritius',
        'Indian/Mayotte',
        'Indian/Reunion',
        'Iran',
        'Israel',
        'Jamaica',
        'Japan',
        'Kwajalein',
        'Libya',
        'MET',
        'MST7MDT',
        'Mexico/BajaNorte',
        'Mexico/BajaSur',
        'Mexico/General',
        'NZ',
        'NZ-CHAT',
        'Navajo',
        'PRC',
        'PST8PDT',
        'Pacific/Apia',
        'Pacific/Auckland',
        'Pacific/Bougainville',
        'Pacific/Chatham',
        'Pacific/Chuuk',
        'Pacific/Easter',
        'Pacific/Efate',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Fiji',
        'Pacific/Funafuti',
        'Pacific/Galapagos',
        'Pacific/Gambier',
        'Pacific/Guadalcanal',
        'Pacific/Guam',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Kiritimati',
        'Pacific/Kosrae',
        'Pacific/Kwajalein',
        'Pacific/Majuro',
        'Pacific/Marquesas',
        'Pacific/Midway',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Norfolk',
        'Pacific/Noumea',
        'Pacific/Pago_Pago',
        'Pacific/Palau',
        'Pacific/Pitcairn',
        'Pacific/Pohnpei',
        'Pacific/Ponape',
        'Pacific/Port_Moresby',
        'Pacific/Rarotonga',
        'Pacific/Saipan',
        'Pacific/Samoa',
        'Pacific/Tahiti',
        'Pacific/Tarawa',
        'Pacific/Tongatapu',
        'Pacific/Truk',
        'Pacific/Wake',
        'Pacific/Wallis',
        'Pacific/Yap',
        'Poland',
        'Portugal',
        'ROK',
        'Singapore',
        'SystemV/AST4',
        'SystemV/AST4ADT',
        'SystemV/CST6',
        'SystemV/CST6CDT',
        'SystemV/EST5',
        'SystemV/EST5EDT',
        'SystemV/HST10',
        'SystemV/MST7',
        'SystemV/MST7MDT',
        'SystemV/PST8',
        'SystemV/PST8PDT',
        'SystemV/YST9',
        'SystemV/YST9YDT',
        'Turkey',
        'UCT',
        'US/Alaska',
        'US/Aleutian',
        'US/Arizona',
        'US/Central',
        'US/East-Indiana',
        'US/Eastern',
        'US/Hawaii',
        'US/Indiana-Starke',
        'US/Michigan',
        'US/Mountain',
        'US/Pacific',
        'US/Pacific-New',
        'US/Samoa',
        'UTC',
        'Universal',
        'W-SU',
        'WET',
        'Zulu',
      ],
      supportRole: false,
    }
  },
  watch: {
    tabValue () {
      this.getAllTabs()
    },
  },
  computed: {
    formReady () {
      if (this.tabValue == 0) {
        return !this.AppNameErrors.length &&
               !this.PackageNameErrors.length &&
               !this.DomainTypeErrors.length &&
               !this.CdnDomainErrors.length &&
               !this.ColourErrors.length &&
               !this.AppTokenErrors.length &&
               !this.AccountErrors.length &&
               !this.appSelectVersionErrors.length
      } else if (this.tabValue == 1) {
        return !this.organization_nameErrors.length &&
               !this.cdn_urlErrors.length &&
               !this.time_zoneErrors.length &&
               //!this.operator_domainErrors.length &&
               !this.launcher_versionErrors.length &&
               !this.brandColorErrors.length &&
               !this.secondaryColorErrors.length &&
               !this.textColorErrors.length &&
               !this.cloud_server_urlErrors.length && 
               !this.LauncherTypeErrors.length
      } else if (this.tabValue == 2) {
        return !this.ip_domainErrors.length &&
               !this.portErrors.length &&
               !this.tokenErrors.length &&
               !this.platformErrors.length &&
               !this.OnpremVersionErrors.length &&
               !this.ProviderNameErrors.length
      } else if (this.tabValue == 3) {
        return !this.instance_nameErrors.length &&
               !this.ipErrors.length &&
               !this.cloud_portErrors.length &&
               !this.cloud_tokenErrors.length &&
               !this.backup_infra_idErrors.length &&
               !this.backup_db_idErrors.length &&
               !this.cloudVersionErrors.length &&
               !this.platformErrors.length
      }
    },
    Checkfile_upload () {
      if (this.logo_file[0] && this.banner_file[0] && this.brand_logo[0] && this.playstore_banner[0] && this.json_file[0]) {
        return true
      } else {
        Vue.notify({ text: 'Please Upload images', type: 'error' })
        return false
      }
    },
    Check_splash () {
      if (this.splash_screen == 'None') {
        return true
      } else if ((this.splash_screen == 'Video') && this.tv_splash_video && this.mobile_splash_video) {
        return true
      } else {
        Vue.notify({ text: 'Please Upload splash files', type: 'error' })
        return false
      }
    },
    dynamicReady () {
      return !this.textsize_typeErrors.length &&
             !this.liveErrors.length &&
             !this.web_seriesErrors.length &&
             !this.tv_showsErrors.length &&
             !this.moviesErrors.length &&
             !this.horizontal_marginErrors.length &&
             !this.corner_radiusErrors.length &&
             !this.mobile_horizontalErrors.length &&
             !this.mobile_verticalErrors.length &&
             !this.tv_verticalErrors.length &&
             !this.tv_horizontalErrors.length &&
             !this.mobile_banner_heightErrors.length &&
             !this.tv_banner_heightErrors.length &&
             !this.mobile_advertisement_heightErrors.length
    },
    fields () {
      if (this.tabValue == 0) {
        return [
          {
            name: 'org_name',
            title: 'Customer Name',
          },
          {
            name: 'app_name',
            title: 'App Name',
          },
          {
            name: 'current_version',
            title: 'App Version',
          },
          {
            name: 'code_type',
            title: 'App Type',
          },
          {
            name: '__slot:release_version',
            title: 'Latest Version',
          },
          {
            name: '__slot:status',
            dataClass: 'text-left',
            title: 'App Status',
          },
          {
            name: '__slot:edit',
            dataClass: 'text-right',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
          {
            name: '__slot:download',
            dataClass: 'text-right',
          },
        ]
      } else if (this.tabValue == 1) {
        return [
          {
            name: 'org_name',
            title: 'Customer Name',
          },
          {
            name: 'cdn_url',
            title: 'CDN Domain',
          },
          {
            name: 'version',
            title: 'Version',
          },
          {
            name: 'type',
            title: 'Type',
          },
          {
            name: '__slot:release_version',
            title: 'Latest Version',
          },
          {
            name: '__slot:status',
            dataClass: 'text-left',
            title: 'App Status',
          },
          {
            name: '__slot:edit',
            dataClass: 'text-right',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      } else if (this.tabValue == 2) {
        return [
          {
            name: 'platform',
            title: 'Platform',
          },
          {
            name: 'org_name',
            title: 'Customer',
          },
          {
            name: 'provider_name',
            title: 'Provider Name'
          },
          {
            name: 'version',
            title: 'Version',
          },
          {
            name: '__slot:release_version',
            title: 'Latest Version',
          },
         // {
           // name: 'license_expiry',
           // title: 'License Expires On',
         // },
          {
            name: 'access_ip',
            title: 'Access',
          },
          {
            name: '__slot:status',
            dataClass: 'text-left',
            title: 'App Status',
          },
          {
            name: '__slot:edit',
            dataClass: 'text-right',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      } else if (this.tabValue == 3) {
        return [
          {
            name: 'instance_name',
            title: 'Infra Name',
          },
          {
            name: 'platform',
            title: 'Platform',
          },
          {
            name: 'version',
            title: 'Version',
          },
          {
            name: '__slot:release_version',
            title: 'Latest Version',
          },
          {
            name: 'access_ip',
            title: 'Access',
          },
          {
            name: '__slot:status',
            dataClass: 'text-left',
            title: 'App Status',
          },
          {
            name: '__slot:edit',
            dataClass: 'text-right',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      }
    },
    filteredData () {
      if (this.tabValue == 0) {
        return search(this.term, this.app_list)
      }
      if (this.tabValue == 1) {
        return search(this.term, this.launcher_list)
      }
      if (this.tabValue == 2) {
        return search(this.term, this.on_prem_list)
      }
      if (this.tabValue == 3) {
        return search(this.term, this.cloud_list)
      }
    },
  },
  methods: {
    getAllTabs () {
      if (this.tabValue == 0) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/release/Apps')
          .then(response => {
            loader.hide()
            this.app_list = response.body
            this.isshowgrid = true
            this.isshowForm = false
          }, error => {
            loader.hide()
          },
          )
      } else if (this.tabValue == 1) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/release/Launcher')
          .then(resp => {
            loader.hide()
            this.launcher_list = resp.body
            this.isshowgrid = true
            this.isshowForm = false
          }, error => {
            loader.hide()
          },
          )
      } else if (this.tabValue == 2) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/release/On_Prem')
          .then(res => {
            loader.hide()
            this.on_prem_list = res.body
            this.isshowgrid = true
            this.isshowForm = false
          }, error => {
            loader.hide()
          },
          )
      } else if (this.tabValue == 3) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/release/Cloud')
          .then(respon => {
            loader.hide()
            this.cloud_list = respon.body
            this.isshowgrid = true
            this.isshowForm = false
          }, error => {
            loader.hide()
          },
          )
      }
    },
    oncloud_version (platform) {
      if (platform == 'Skie') {
        this.cloud_version_array = this.versions_Arr.skie_versions
      } if (platform == 'Skie Player') {
        this.cloud_version_array = this.versions_Arr.skie_player_versions
      } if (platform == 'OTA') {
        this.cloud_version_array = this.versions_Arr.ota_versions
      }
    },
    onprem_version (platform) {
      if (platform == 'DRM') {
        this.onperm_version = this.versions_Arr.drm_versions
      } if (platform == 'SMS') {
        this.onperm_version = this.versions_Arr.sms_versions
      } if (platform == 'CDN') {
        this.onperm_version = this.versions_Arr.cdn_versions
      }
    },
    onlauncher_version () {
      if (this.launcher_type == 'Hepi'){
        this.launcher_version_array = this.versions_Arr.hepi_versions
      } else if(this.launcher_type == 'OTT'){
        this.launcher_version_array = this.versions_Arr.ott_versions
      } else if(this.launcher_type == 'IPTV+OTT'){
        this.launcher_version_array = this.versions_Arr.iptv_versions
      }
    },
    codeType (val) {
      if (val.replace(/ /g, '') == 'Standard') {
        this.select_version_array = this.versions_Arr.standard_versions
      } else if (val.replace(/ /g, '') == 'Premium') {
        this.select_version_array = this.versions_Arr.premium_versions
      }
    },
    getApk (data) {
      const anchor = document.createElement('a')
      anchor.href = data
      anchor.target = '_self'
      anchor.click()
      URL.revokeObjectURL(data)
    },
    getAllApps () {
      const user_id = Vue.$cookies.get('user-id')
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/operator_app/' + this.User.org_id + '/' + this.User.roles).then(response => {
        loader.hide()
        let index = 0
        this.operators = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowgrid = true
        this.getAllAppsInterval()
      }, err => {
        loader.hide()
      })
    },
    getAllAccounts () {
      this.$http.get(config.menu.host + '/playaccounts').then(responseData => {
        this.playstoreTypeOptions = responseData.body
        this.playstoreTypeOptions = this.playstoreTypeOptions.map(function (prop) {
          return prop.account_name
        })
      }, error => {
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    refresh_call () {
      var path
      if (this.tabValue == 0) {
        path = '/Apps'
      }
      if (this.tabValue == 1) {
        path = '/Launcher'
      }
      if (this.tabValue == 2) {
        path = '/On_prem'
      }
      if (this.tabValue == 3) {
        path = '/Cloud'
      }
      // const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/release/' + path)
        .then(response => {
          // loader.hide()
          this.app_list = response.body
          this.isshowgrid = true
          this.isshowForm = false
          this.isShowLauncher = false
          this.isShowCloud = false
          this.isOnprem = false
          this.getAllTabs()
          this.$http.get(config.menu.host + '/org')
            .then(resp => {
              // loader.hide()
              this.organization_nameArr = []
              this.organization_nameArr = resp.body.filter(function (value, count) {
                value.id = count
                return !response.body.some(function (data) {
                  return value.org_name === data.name
                })
              })
              this.$http.get(config.menu.host + '/release/versions')
                .then(res => {
                  // loader.hide()

                  this.versions_Arr = res.body
                  this.isshowgrid = true
                  this.isshowForm = false
                  this.isShowLauncher = false
                  this.isShowCloud = false
                  this.isOnprem = false
                }, error => {
                  // loader.hide()
                  if (error && error.body) {
                    Vue.notify({ text: error.body, ytpe: 'error' })
                  }
                })
            }, error => {
              // loader.hide()
              if (error && error.body) {
                Vue.notify({ text: error.body, type: 'error' })
              }
            },
            )
        }, error => {
          // loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        },
        )
    },
    getOrgInfo () {
      this.$http.get(config.menu.host + '/org').then(response => {
        response.body.map(function (data, i) {
          data.id = i + 1
        })
        this.operator_org_obj = response.body.filter(function (entry) {
          return (entry.org_type != 'ADMIN')
        })
      }, error => {
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    getAllAppsInterval () {
      this.interval = setInterval(function () {
        this.$http.get(config.menu.host + '/operator_app/' + this.User.org_id + '/' + this.User.roles).then(response => {
          let index = 0
          this.operators = response.body.map(function (item) {
            item.id = index++
            item.sno = index
            item.is_existing = true
            item.checkbox_value = false
            return item
          })
          this.isshowgrid = true
        }, err => {
        })
      }.bind(this), 10000)
    },
    getUserInfo () {
      const user_id = Vue.$cookies.get('user-id')
      this.$http.get(config.menu.host + '/user/' + user_id)
        .then(response => {
          this.User = response.data
        })
    },
    Checkreupload () {
      if (this.reupolad == false) {
        this.reupolad = false
        this.isReupload = false
      } else {
        this.reupolad = true
        this.isReupload = true
      }
    },
    Checkgoogleplay () {
      if (this.play_auto_update == false) {
        this.play_auto_update = false
        this.isPlayStore = false
      } else {
        this.play_auto_update = true
        this.isPlayStore = true
      }
    },
    CheckPlaystore (type) {
      if (typeof (type) !== 'undefined') {
        var image_file;
        (type == 'playstore_banner') ? image_file = this.playstore_banner[0] : image_file = this.brand_logo[0]
        if ((image_file !== undefined)) {
          var file_name = image_file.name.split('.').pop()
          if (file_name.toLowerCase() == 'png') {
            var width, height
            const img = new Image()
            if (type === 'playstore_banner') {
              width = '1024'
              height = '500'
            }
            if (type === 'brand') {
              width = '512'
              height = '512'
            }
            const reader = new FileReader()
            reader.readAsDataURL(image_file)
            reader.onload = evt => {
              img.onload = () => {
                if ((img.width != width) && (img.height != height)) {
                  (type == 'playstore_banner') ? this.playstore_banner = '' : this.brand_logo = ''
                  var flag = this.playstore_banner ? 'playstore_banner' : 'brand'
                  if (flag == 'playstore_banner') {
                  //   this.playstore_banner = []
                  // } else {
                    this.brand_logo = []
                  }
                  Vue.notify({ text: 'Please check the image size', type: 'error' })
                }
              }
              img.src = evt.target.result
            }
          } else {
            (type == 'playstore_banner') ? this.playstore_banner = '' : this.brand_logo = ''
            Vue.notify({ text: 'Please check the image format', type: 'error' })
          }
        }
      }
    },
    CheckFile (type) {
      if(this.logo_file[0] == undefined || this.logo_file.length == 0){
        this.previewImage = null
      }
      if (this.banner_file[0] == undefined || this.banner_file.length == 0 ){
        this.previewImage1 = null
      }

      if (typeof (type) !== 'undefined') {
        var image_file;
        (type == 'logo') ? image_file = this.logo_file[0] : image_file = this.banner_file[0]
        if (((type == 'logo') || (type == 'banner')) && (image_file !== undefined)) {
          var file_name = image_file.name.split('.').pop()
          if (file_name.toLowerCase() === 'png') {
            const img = new Image()
            let width = '512'
            let height = '512'
            if (type === 'banner') {
              width = '1280'
              height = '720'
            }
            this.banner_name = image_file.name
            const reader = new FileReader()
            reader.readAsDataURL(image_file)
            reader.onload = evt => {
              if(type == 'logo'){
                this.previewImage = evt.target.result
                this.oldLogo = false
              }else if(type == 'banner') {
                this.previewImage1 = evt.target.result
                this.oldBanner = false
              }
              img.onload = () => {
                this.banner_name = image_file.name
                if (img.width == width && img.height == height) {
                  (type == 'logo') ? this.logo_name = image_file.name : this.banner_name = image_file.name
                } 
                else {
                  (type === 'logo') ? this.logo_file = '' : this.banner_file = ''
                  var flag = this.logo_file ? 'logo' : 'banner'
                  if (flag === 'logo') {
                  //   this.logo_file = []
                  // } else {
                    this.banner_file = []
                  }
                  if(this.logo_file == ''){
                    this.previewImage = null
                  }
                  if(this.banner_file == ''){
                    this.previewImage1 = null
                  }
                  Vue.notify({ text: 'Please check the image size', type: 'error' })
                }
              }
              img.src = evt.target.result
            }
          } else {
            (type === 'logo') ? this.logo_file = '' : this.banner_file = ''
            Vue.notify({ text: 'Please check the image Format', type: 'error' })
          }
        }
      }  
    },
    createLauncherApp () {
      this.organization_nameErrors = this.organization_name.org_name ? [] : ['Organization Name is required']
      this.cdn_urlErrors = this.cdn_url ? [] : ['URL is required']
      this.time_zoneErrors = this.time_zone ? [] : ['Time Zone is required']
      //this.operator_domainErrors = this.operator_domain ? [] : ['Domain is required']
      this.brandColorErrors = this.brandColor ? [] : ['Brand Color is required']
      this.secondaryColorErrors = this.secondary_color ? [] : ['Secondary Color is required']
      this.textColorErrors = this.text_color ? [] : ['Text Color is required']
      this.launcher_versionErrors = this.launcher_version ? [] : ['']
      this.cloud_server_urlErrors = this.ott_url ? [] : ['URL is required']
      this.LauncherTypeErrors = this.launcher_type ? [] : ['Type is required']


      if(this.cdn_url[this.cdn_url.length - 1] != "/"){
        return Vue.notify({ text: 'The CDN URL should be end with the slash', type: 'error'})
      }
      if(this.ott_url[this.ott_url.length - 1] != "/"){
       return Vue.notify({ text: 'The Cloud Server URL should be end with the slash', type: 'error'}) 
      }
      if (!this.formReady) {
        return
      }
      if (this.launcher_logo == 'Image') {
        if (this.logo_file[0] && this.banner_file[0]) {
        } else {
          Vue.notify({ text: 'Please Upload images', type: 'error' })
          return
        }
      }

      if (this.launcher_logo == 'Video' ) { 
        if (this.logo_file[0]) {
        }else{
          return Vue.notify({ text: 'Please upload Images and Video', type: 'error' })
        }
      }
      var is_splash_video = false;
      if(this.launcher_logo == 'Video'){
        is_splash_video = true;
      }    

      var payload = new FormData()
      payload.append('logo', this.logo_file[0])
      payload.append('banner', this.banner_file[0])
      payload.append('Video', this.launcher_video)
      payload.append('org_name', this.organization_name.org_name)
      payload.append('operator_name', this.organization_name.org_name)
      payload.append('time_zone', this.time_zone)
      payload.append('cdn_url', this.cdn_url)
      payload.append('latest_version', this.launcher_version)
      //payload.append('operator_domain_prefix', this.operator_domain)
      payload.append('brand_color', this.brandColor)
      payload.append('secondary_color', this.secondary_color)
      payload.append('text_color', this.text_color)
      payload.append('logo_name', this.logo_name)
      payload.append('banner_name', this.banner_name)
      payload.append('operator_id', this.organization_name.org_id)
      payload.append('mso_org_id', this.organization_name.org_id)
      payload.append('ott_url', this.ott_url)
      payload.append('type', this.launcher_type)
      payload.append('is_splash_video',is_splash_video)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/launcher', payload)
        .then(res => {
          loader.hide()
          if (res && res.body) {
            Vue.notify({ text: res.body, type: 'success' })
          }
          this.list()
          this.refresh_call()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    updateLauncherApp () {
      this.launcher_versionErrors = this.launcher_version ? [] : ['']

      if (!this.formReady) {
        return
      }

      var is_splash_video = false;
      if(this.launcher_logo == 'Video'){
        is_splash_video = true;
      }

      var payload = new FormData()
      payload.append('logo', this.logo_file[0])
      payload.append('banner', this.banner_file[0])
      payload.append('Video', this.launcher_video)
      payload.append('org_name', this.org_name)
      payload.append('operator_name', this.org_name)
      payload.append('time_zone', this.time_zone)
      payload.append('cdn_url', this.cdn_url)
      payload.append('latest_version', this.launcher_version)
      //payload.append('operator_domain_prefix', this.operator_domain)
      payload.append('brand_color', this.brandColor)
      payload.append('secondary_color', this.secondary_color)
      payload.append('text_color', this.text_color)
      payload.append('logo_name', this.logo_name)
      payload.append('banner_name', this.banner_name)
      payload.append('operator_id', this.operator_id)
      payload.append('mso_org_id', this.operator_id)
      payload.append('ott_url', this.ott_url)
      payload.append('type', this.launcher_type)
      payload.append('is_splash_video',is_splash_video)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/launcher', payload)
        .then(res => {
          loader.hide()
          if (res && res.body) {
            Vue.notify({ text: res.body, type: 'success' })
          }
          this.refresh_call()
          this.isshowgrid = true
          this.list()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },

    createOnprem () {
      //this.organization_nameErrors = this.organization_name.org_name ? [] : ['Organization Name is required']
      this.ip_domainErrors = this.ip_domain ? [] : ['IP is required']
      this.portErrors = this.port ? [] : ['Port is required']
      this.tokenErrors = this.token ? [] : ['Token is required']
      this.platformErrors = this.platform ? [] : ['']
      this.OnpremVersionErrors = this.platform_version ? [] : ['Version is required']

      if ((this.platform == 'CDN') && (this.organization_name == '')) {
        return Vue.notify({ text: 'Please Select Organization Name', type: 'error' })
      } else if ((this.platform == 'SMS' || this.platform =='DRM') && (this.provider_name == '')) {
        return Vue.notify({ text: 'Please Select Provider Name', type: 'error' })
      }

      if (!this.formReady) {
        return
      }

      var payload = {
        org_name: this.organization_name.org_name,
        access_ip: this.ip_domain,
        access_port: this.port,
        access_token: this.token,
        platform: this.platform,
        version: this.platform_version,
        provider_name: this.provider_name
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/onprem', payload)
        .then(res => {
          loader.hide()
          if (res && res.body) {
            Vue.notify({ text: res.body, type: 'success' })
          }
          this.list()
          this.refresh_call()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    updateOnprem () {
      this.ip_domainErrors = this.ip_domain ? [] : ['IP is required']
      this.portErrors = this.port ? [] : ['Port is required'] 
      this.tokenErrors = this.token ? [] : ['Token is required']
      this.OnpremVersionErrors = this.platform_version ? [] : ['Version is required']

      if (!this.formReady) {
        return
      }

      var payload = {
        onprem_id: this.onprem_id,
        org_name: this.org_name,
        access_ip: this.ip_domain,
        access_port: this.port,
        access_token: this.token,
        platform: this.platform,
        version: this.platform_version,
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/onprem/update', payload)
        .then(res => {
          loader.hide()
          if (res && res.body) {
            Vue.notify({ text: res.body, type: 'success' })
          }
          this.list()
          this.refresh_call()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    createCloudApp () {
      this.instance_nameErrors = this.instance_name ? [] : ['Instance Name is required']
      this.ipErrors = this.ip ? [] : ['IP is required']
      this.cloud_portErrors = this.cloud_port ? [] : ['Port is required']
      this.cloud_tokenErrors = this.cloud_token ? [] : ['Token is required']
      this.backup_infra_idErrors = this.backup_infra_id ? [] : ['Backup infra id is required']
      this.backup_db_idErrors = this.backup_db_id ? [] : ['Backup db id is required']
      this.cloudVersionErrors = this.cloud_version ? [] : ['']
      this.platformErrors = this.platform ? [] : ['']

      if (!this.formReady) {
        return
      }

      var payload = {
        instance_name: this.instance_name,
        access_ip: this.ip,
        access_port: this.cloud_port,
        backup_infra_id: this.backup_infra_id,
        backup_db_id: this.backup_db_id,
        version: this.cloud_version,
        access_token: this.cloud_token,
        platform: this.platform,
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/cloud', payload)
        .then(res => {
          loader.hide()
          if (res && res.body) {
            Vue.notify({ text: res.body, type: 'success' })
          }
          this.list()
          this.refresh_call()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    getSelectedOrg (selectedOrg) {
      this.organization_id = selectedOrg.org_id
      this.organization_name = selectedOrg.org_name
    },
    selectTextSize (dynamic) {
      if (dynamic == 'MIN') {
        this.isMin = true
        this.isMid = false
        this.isMax = false
        this.horizontal_margin = '2'
        this.corner_radius = '2'
        this.mobile_horizontal = '4'
        this.mobile_vertical = '5'
        this.tv_horizontal = '6'
        this.tv_vertical = '7'
        this.border_size = '1'
        this.zoom_size = 'S'
        this.mobile_banner_height = '190'
        this.tv_banner_height = '300'
        this.mobile_advertisement_height = '150'
      } else if (dynamic == 'MAX') {
        this.isMax = true
        this.isMid = false
        this.isMin = false
        this.horizontal_margin = '8'
        this.corner_radius = '10'
        this.mobile_horizontal = '2'
        this.mobile_vertical = '3'
        this.tv_horizontal = '4'
        this.tv_vertical = '5'
        this.border_size = '3'
        this.zoom_size = 'XL'
        this.mobile_banner_height = '200'
        this.tv_banner_height = '720'
        this.mobile_advertisement_height = '170'
      } else if (dynamic == 'MID') {
        this.isMid = true
        this.isMin = false
        this.isMax = false
        this.horizontal_margin = ''
        this.corner_radius = ''
        this.mobile_horizontal = ''
        this.mobile_vertical = ''
        this.tv_horizontal = ''
        this.tv_vertical = ''
        this.border_size = ''
        this.zoom_size = ''
        this.mobile_banner_height = ''
        this.tv_banner_height = ''
        this.mobile_advertisement_height = ''
      }
    },
    finish () {
      this.textsize_typeErrors = this.textsize_type ? [] : ['required']
      this.liveErrors = this.live ? [] : ['required']
      this.web_seriesErrors = this.web_series ? [] : ['required']
      this.tv_showsErrors = this.tv_shows ? [] : ['required']
      this.moviesErrors = this.movies ? [] : ['required']
      this.horizontal_marginErrors = this.horizontal_margin ? [] : ['Horizontal Margin is required']
      this.corner_radiusErrors = this.corner_radius ? [] : ['Corner radius is required']
      this.mobile_horizontalErrors = this.mobile_horizontal ? [] : ['Hoizontal is required']
      this.mobile_verticalErrors = this.mobile_vertical ? [] : ['Vertical is required']
      this.tv_horizontalErrors = this.tv_horizontal ? [] : ['Horizontal is required']
      this.tv_verticalErrors = this.tv_vertical ? [] : ['Vertical is required']
      this.mobile_banner_heightErrors = this.mobile_banner_height ? [] : ['Banner height is required']
      this.tv_banner_heightErrors = this.tv_banner_height ? [] : ['Banner height is required']
      this.mobile_advertisement_heightErrors = this.mobile_advertisement_height ? [] : ['Advertisement height is required']
      this.border_sizeErrors = this.border_size ? [] : ['Border size is required']
      this.zoom_sizeErrors = this.zoom_size ? [] : ['Zoom size is required']

      if ((Number(this.horizontal_margin) < 2) || (Number(this.horizontal_margin) > 8)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select Horizontal Margin size between 2 to 8', type: 'error' })
      } else if ((Number(this.corner_radius) < 2) || (Number(this.corner_radius) > 10)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select Corner Radius size between 2 to 10', type: 'error' })
      } else if ((Number(this.mobile_horizontal) < 2) || (Number(this.mobile_horizontal) > 4)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select Mobile Horizontal size between 2 to 4', type: 'error' })
      } else if ((Number(this.mobile_vertical) < 3) || (Number(this.mobile_vertical) > 5)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select Mobile Vertical size between 3 to 5', type: 'error' })
      } else if ((Number(this.tv_horizontal) < 4) || (Number(this.tv_horizontal) > 6)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select TV Horizontal size between 5 to 7', type: 'error' })
      } else if ((Number(this.tv_vertical) < 5) || (Number(this.tv_vertical) > 7)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select TV Vertical size between 2 to 8', type: 'error' })
      } else if (this.tile_style == 'Border') {
        if ((Number(this.border_size) < 1) || (Number(this.border_size) > 3)) {
          this.isShowDynamic = true
          return Vue.notify({ text: 'Select Border size between 1 to 3', type: 'error' })
        }
      } else if ((Number(this.mobile_banner_height) < 190) || (Number(this.mobile_banner_height) > 300)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select Mobile Banner size between 190 to 300', type: 'error' })
      } else if ((Number(this.tv_banner_height) < 300) || (Number(this.tv_banner_height) > 720)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select TV Banner size between 300 to 720', type: 'error' })
      } else if ((Number(this.mobile_advertisement_height) < 150) || (Number(this.mobile_advertisement_height) > 170)) {
        this.isShowDynamic = true
        return Vue.notify({ text: 'Select Mobile Advertisement size between 150 to 170', type: 'error' })
      }

      if ((this.tile_style == 'Border') && (this.border_sizeErrors.length > 0)) {
        Vue.notify({ text: 'Please enter border size', type: 'error' })
      } else if ((this.tile_style == 'Zoom') && (this.zoom_sizeErrors.length > 0)) {
        Vue.notify({ text: 'Please enter zoom size', type: 'error' })
      }

      if (!this.dynamicReady) {
        this.isShowDynamic = true
      } else {
        this.dynamicConf = true
      }
    },
    createApp () {
      this.AppNameErrors = this.app_name ? [] : ['AppName is required']
      this.organization_nameErrors = this.organization_name.org_name ? [] : ['Organization Name is required']
      this.PackageNameErrors = this.package_name ? [] : ['Package name is required']
      this.DomainTypeErrors = this.protocol ? [] : ['Select Protocol is required']
      this.CdnDomainErrors = this.cdn_domain ? [] : ['Cdn Domain is required']
      this.ColourErrors = this.brand_color ? [] : ['Brand Color is required']
      this.splashScreenError = this.splash_screen ? [] : ['Splash Screen is required']
      this.codeTypeError = this.code_type ? [] : ['Code Type is required']
      this.AppTokenErrors = this.app_token ? [] : ['Token is required']
      this.appSelectVersionErrors = this.select_version ? [] : ['']

      if (this.User.roles === 'RESELLER') {
        this.operatorOrgInfoErrors = this.operator_org_id ? [] : ['Operator Organization is required']
        if (this.operatorOrgInfoErrors.length > 0) {
          return
        }
      }
      if (!this.dynamicConf) {
        return Vue.notify({ text: 'Please fill the dynamic categories', type: 'error' })
      }
      if (!this.formReady) {
        return
      }

      if (!REGEX.nameNumericRegex(this.app_name)) {
        return this.AppNameErrors.push('Only Alphanumeric is allowed')
      }
      if (!this.Checkfile_upload) {
        return
      }
      if ((this.splash_screen == 'Video') && (!this.Check_splash)) {
        return
      }
      var dynamic_conf = {
        text_size: this.textsize_type,
        tile_orientation: {
          live: this.live,
          ws: this.web_series,
          ts: this.tv_shows,
          mv: this.movies,
        },
        default_tile_style: {
          horizontal_margin: this.horizontal_margin,
          corner_radius: this.corner_radius,
        },
        tile_count: {
          mobile: {
            horizontal: this.mobile_horizontal,
            vertical: this.mobile_vertical,
          },
          tv: {
            horizontal: this.tv_horizontal,
            vertical: this.tv_vertical,
          },
        },
        selected_tile_style: {
          is_border: this.tile_style == 'Border',
          zoom: this.zoom_size,
          border_size: this.border_size,
        },
        banner_height: {
          mobile: this.mobile_banner_height,
          tv: this.tv_banner_height,
        },
        advertisement_height: {
          mobile: this.mobile_advertisement_height,
        },
      }
      var json = JSON.stringify(dynamic_conf)

      var payload = new FormData()
      payload.append('logo', this.logo_file[0])
      payload.append('banner', this.banner_file[0])
      payload.append('google_json', this.json_file[0])
      payload.append('app_token', this.app_token)
      payload.append('playstore_banner', this.playstore_banner[0])
      payload.append('splash_screen', this.splash_screen)
      payload.append('tv_splash_video', this.tv_splash_video)
      payload.append('mobile_splash_video', this.mobile_splash_video)
      payload.append('brand_logo', this.brand_logo[0])
      payload.append('app_name', this.app_name)
      payload.append('package_name', this.package_name)
      payload.append('cdn_domain', this.cdn_domain)
      payload.append('code_type', this.code_type)
      payload.append('port', this.port)
      payload.append('protocol', this.protocol)
      payload.append('brand_color', this.brand_color)
      payload.append('logo_name', this.logo_name)
      payload.append('banner_name', this.banner_name)
      payload.append('latest_version', this.select_version)
      payload.append('org_name', this.organization_name.org_name)
      payload.append('operator_name', this.organization_name.org_name)
      payload.append('operator_id', this.organization_name.org_id)
      payload.append('reseller_org_id', this.organization_name.org_id)
      payload.append('dynamic_conf', json)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/operator_app', payload).then(responseData => {
        this.toastText = responseData.body.data
        loader.hide()
        this.launchToast()
        this.list()
        this.refresh_call()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          loader.hide()
          Vue.notify({ text: errResponse.body, type: 'error' })
          this.refresh_call()
        }
      })
    },
    updateApps () {
      this.DomainTypeErrors = this.protocol ? [] : ['Select Protocol is required']
      this.CdnDomainErrors = this.cdn_domain ? [] : ['Cdn Domain is required']
      this.ColourErrors = this.brand_color ? [] : ['Brand Color is required']
      this.DomainTypeErrors = this.cdn_domain ? [] : ['Select Domain is required']
      this.codeTypeError = this.code_type ? [] : ['Code Type is required']
      this.AccountErrors = this.play_console_account ? [] : ['Account is required']
      if (!this.formReady) {
        return
      }
      if (this.play_console_account == '') {
        return
      }
      var dynamic_conf = {
        text_size: this.textsize_type,
        tile_orientation: {
          live: this.live,
          ws: this.web_series,
          ts: this.tv_shows,
          mv: this.movies,
        },
        default_tile_style: {
          horizontal_margin: this.horizontal_margin,
          corner_radius: this.corner_radius,
        },
        tile_count: {
          mobile: {
            horizontal: this.mobile_horizontal,
            vertical: this.mobile_vertical,
          },
          tv: {
            horizontal: this.tv_horizontal,
            vertical: this.tv_vertical,
          },
        },
        selected_tile_style: {
          is_border: this.tile_style == 'Border',
          zoom: this.zoom_size,
          border_size: this.border_size,
        },
        banner_height: {
          mobile: this.mobile_banner_height,
          tv: this.tv_banner_height,
        },
        advertisement_height: {
          mobile: this.mobile_advertisement_height,
        },
      }
      var json = JSON.stringify(dynamic_conf)

      var payload = new FormData()
      payload.append('logo', this.logo_file[0])
      payload.append('banner', this.banner_file[0])
      payload.append('playstore_banner', this.playstore_banner[0])
      payload.append('brand_logo', this.brand_logo[0])
      payload.append('splash_screen', this.splash_screen)
      payload.append('tv_splash_video', this.tv_splash_video)
      payload.append('mobile_splash_video', this.mobile_splash_video)
      payload.append('app_name', this.app_name)
      payload.append('app_token', this.app_token)
      payload.append('package_name', this.package_name)
      payload.append('cdn_domain', this.cdn_domain)
      payload.append('port', this.port)
      payload.append('protocol', this.protocol)
      payload.append('brand_color', this.brand_color)
      payload.append('logo_name', this.logo_name)
      payload.append('banner_name', this.banner_name)
      payload.append('operator_id', this.operator_id)
      payload.append('reseller_org_id', this.operator_id)
      payload.append('org_name', this.org_name)
      payload.append('operator_name', this.org_name)
      payload.append('version_code', this.app_version_code)
      payload.append('play_auto_update', this.play_auto_update)
      payload.append('play_console_account', this.play_console_account)
      payload.append('latest_version', this.select_version)
      payload.append('code_type', this.code_type)
      payload.append('dynamic_conf', json)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/operator_app', payload)
        .then(responseData => {
          loader.hide()
          Vue.notify({ text: responseData.body, type: 'success' })
          this.list()
          this.refresh_call()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
    },

    updateCloudApp () {
      this.ipErrors = this.ip ? [] : ['IP is required']
      this.cloud_portErrors = this.cloud_port ? [] : ['Port is required']
      this.cloud_tokenErrors = this.cloud_token ? [] : ['Token is required']
      this.backup_infra_idErrors = this.backup_infra_id ? [] : ['Infra ID is required']
      this.backup_db_idErrors = this.backup_db_id ? [] : ['Database ID is required']
      this.cloudVersionErrors = this.cloud_version ? [] : ['']

      if (!this.formReady) {
        return
      }

      var payload = {
        instance_name: this.instance_name,
        access_ip: this.ip,
        access_port: this.cloud_port,
        backup_infra_id: this.backup_infra_id,
        backup_db_id: this.backup_db_id,
        version: this.cloud_version,
        access_token: this.cloud_token,
        platform: this.platform,
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/cloud/update', payload)
        .then(responseData => {
          loader.hide()
          Vue.notify({ text: responseData.body, type: 'success' })
          this.list()
          this.refresh_call()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
    },
    Checkjson () {
      // var file = this.json_file[0];
      //  var file_name = file.name.split('.').pop()
      //   if (!file_name.toLowerCase() == 'json') {
      //     this.json_file=''
      //     Vue.notify({ text: 'please check the file Format', type: 'error' })
      //   }
    },
    // validateLauncherVideo (eve) {
    //   var video = eve.target.files[0]
    //   var size = eve.target.size
    //   if ((size / (1024 * 1024)) <= 200) {
    //     this.launcherVideoFileName = video.name
    //     this.launcher_video = video
    //     return true
    //   } else {
    //     alert('please upload video less than 200 MB')
    //     this.launcherVideoFileName = ''
    //     return false
    //   }
    // },
    validateVideo (eve, type) {
      var video = eve.target.files[0]
      var size = eve.target.size
      if ((size / (1024 * 1024)) <= 200) {
        if (type == 'tv') {
          this.tvVideoFileName = video.name
          this.tv_splash_video = video
        } else if (type == 'Launcher') {
          this.launcherVideoFileName = video.name
          this.launcher_video = video
        } else {
          this.mobileVideoFileName = video.name
          this.mobile_splash_video = video
        }
        return true
      } else {
        alert('please upload video less than 200 MB')
        this.tvVideoFileName = ''
        this.mobileVideoFileName = ''
        this.launcherVideoFileName = ''
        return false
      }
    },
    updateTab (row) {
      if (this.tabValue == 0) {
        this.isShowList = true
        this.isshowgrid = false
        this.selectedRow = row
        this.isshowForm = false
      } else if (this.tabValue == 1) {
        this.isShowList = true
        this.isshowgrid = false
        // this.isShowLauncher = false
        this.isLauncherCreate = false
        this.isLauncherUpdate = false
        this.launcherupdate = false
        this.selectedRow = row
      } else if (this.tabValue == 2) {
        this.isshowgrid = false
        this.isShowList = true
        this.isshowForm = false
        this.selectedRow = row
      } else if (this.tabValue == 3) {
        this.isShowList = true
        // this.isShowCloud = true
        // this.isCloudCreate = false
        // this.isCloudUpdate = false
        this.selectedRow = row
        // this.isshowForm = false
        this.isshowgrid = false
      }
    },
    updateNow (row, data) {
      this.isshowgrid = false
      if (this.tabValue == 0) {
        this.title = 'Update Apps'
      } else if (this.tabValue == 1) {
        this.title = 'Update Launcher'
      } else if (this.tabValue == 2) {
        this.title = 'Update On Prem'
      } else if (this.tabValue == 3) {
        this.title = 'Update Cloud'
      }
      if(row.is_splash_video == 1){
        this.launcher_logo =  'Video'
      }else{
        this.launcher_logo = 'Image'
      }

      clearInterval(this.interval)
      this.getAllAccounts()
      this.platform_type = false
      this.onPremPlatform = true
      this.logo_file = []
      this.banner_logo = []
      this.brand_logo = []
      this.json_file = []
      this.playstore_banner = []
      this.AccountErrors = []
      this.select_version = row.release_version
      this.codeTypeError = []
      this.launcher_versionErrors = []
      this.LauncherTypeErrors = []
      this.ip_domainErrors = []
      this.portErrors = []
      this.tokenErrors = [] 
      this.ipErrors = []
      this.cloud_portErrors = []
      this.cloud_tokenErrors = []
      this.backup_infra_idErrors = []
      this.backup_db_idErrors = []
      this.ProviderNameErrors = []
      this.platform = row.platform
      if(row.platform == "SMS" || row.platform == "DRM"){
        this.platform_type = true
        this.onPremPlatform = false
        this.provider_name = row.provider_name
      }
      this.organization_name = row.org_name
      this.onprem_id = row.onprem_id
      this.org_name = row.org_name
      this.app_token = row.token
      this.app_name = row.app_name
      this.package_name = row.package_name
      this.protocol = row.protocol
      this.cdn_domain = row.cdn_domain
      this.port = row.port
      this.brand_color = row.brand_color
      this.splash_screen = row.splash_screen
      this.operator_id = row.operator_id
      this.version_code = row.version_code
      this.play_auto_update = row.play_auto_update
      this.play_console_account = (row.play_console_account) ? row.play_console_account : ''
      this.code_type = row.code_type
      this.instance_name = row.instance_name
      this.ip = row.access_ip
      this.cloud_port = row.access_port
      this.cloud_token = row.access_token
      this.backup_infra_id = row.backup_infra_id
      this.backup_db_id = row.backup_db_id
      this.cloud_version = row.version
      this.cdn_url = row.cdn_url
      this.time_zone = row.time_zone
      //this.operator_domain = row.operator_domain_prefix
      this.brandColor = row.brand_color
      this.secondary_color = row.secondary_color
      this.text_color = row.text_color
      this.ip_domain = row.access_ip
      this.port = row.access_port
      this.token = row.access_token
      this.launcher_version = row.version
      this.platform_version = row.version
      this.ott_url = row.ott_url
      this.app_version_name = row.version_name
      this.app_version_code = row.version_code
      this.launcher_type = row.type
      this.image = false;
      this.cloud_server_urlErrors = []
      this.oldLogo = true
      this.oldBanner = true
      this.launcher_video = []
      // this.isCreate = false
      if (this.tabValue == 0 ) {
	      if(row.dynamic_conf !=null){
          var input = (row.dynamic_conf)
  	    row.dynamic_conf = JSON.parse(input)
	    row.dynamic_conf = (typeof(row.dynamic_conf) == 'string') ? JSON.parse(row.dynamic_conf) : row.dynamic_conf
          this.textsize_type = row.dynamic_conf.text_size
          this.live = row.dynamic_conf.tile_orientation.live
          this.web_series = row.dynamic_conf.tile_orientation.ws
          this.tv_shows = row.dynamic_conf.tile_orientation.ts
          this.movies = row.dynamic_conf.tile_orientation.mv
          this.horizontal_margin = row.dynamic_conf.default_tile_style.horizontal_margin
          this.corner_radius = row.dynamic_conf.default_tile_style.corner_radius
          this.mobile_horizontal = row.dynamic_conf.tile_count.mobile.horizontal
          this.mobile_vertical = row.dynamic_conf.tile_count.mobile.vertical
          this.tv_horizontal = row.dynamic_conf.tile_count.tv.horizontal
          this.tv_vertical = row.dynamic_conf.tile_count.tv.vertical
          this.border_size = row.dynamic_conf.selected_tile_style.border_size
          this.zoom_size = row.dynamic_conf.selected_tile_style.zoom_size
          this.mobile_banner_height = row.dynamic_conf.banner_height.mobile
          this.tv_banner_height = row.dynamic_conf.banner_height.tv
          this.mobile_advertisement_height = row.dynamic_conf.advertisement_height.mobile
	      }
        if (data == 'onEdit') {
          this.isshowgrid = false
          this.isshowForm = true
          this.isShowList = false
          this.isCreate = false
          this.codeType(row.code_type)
          this.isUpdate = true
          this.updatenow = false
	  this.select_version = row.current_version
        } else if (data == 'onLink') {
          this.isshowgrid = false
          this.isshowForm = true
          this.isShowList = false
          this.isCreate = false
          this.select_version = row.release_version
          this.isUpdate = true
          this.updatenow = true
        }
      }
      if (this.tabValue == 1) {
        if (data == 'onEdit') {
          this.isShowLauncher = true
          this.isshowForm = false
          this.isUpdate = false
          this.isshowgrid = false
          this.isLauncherCreate = false
          this.onlauncher_version()
          this.isLauncherUpdate = true
          this.launcherupdate = false
        } else if (data == 'onLink') {
          this.isshowgrid = false
          this.isShowLauncher = true
          this.isLauncherCreate = false
          this.isLauncherUpdate = true
          this.launcher_version = row.release_version
          this.isShowList = false
          this.launcherupdate = true
        }
      } else if (this.tabValue == 2) {
        if (data == 'onEdit') {
          this.isshowgrid = false
          this.isOnprem = true
          this.isOnpremCreate = false
          this.onprem_version(row.platform)
          this.isshowForm = false
          this.isShowList = false
          this.isOnpremUpdate = true
          this.onprem_update = false
          this.selectedRow = row
          this.onprem_latest_version = row.release_version
        } else if (data == 'onLink') {
          this.isshowgrid = false
          this.isOnprem = true
          this.isOnpremCreate = false
          this.isshowForm = false
          this.isShowList = false
          this.onprem_update = true
          this.isOnpremUpdate = true
          this.platform_version = row.release_version
        }
      } else if (this.tabValue == 3) {
        if (data == 'onEdit') {
          this.isshowgrid = false
          this.isshowForm = false
          this.isShowList = false
          this.isShowCloud = true
          this.oncloud_version(row.platform)
          this.isCloudCreate = false
          this.isCloudUpdate = true
          this.cloud_update = false
          this.cloud_latest_version = row.release_version
        } else if (data == 'onLink') {
          this.isshowgrid = false
          this.isShowList = false
          this.isshowForm = false
          this.isShowCloud = true
          this.isCloudCreate = false
          this.isCloudUpdate = true
          this.cloud_update = true
          this.cloud_version = row.release_version
        }
      }
    },
    remove (row) {
      if (this.tabValue == 0) {
        this.deleteTitle = 'Delete App'
      } else if (this.tabValue == 1) {
        this.deleteTitle = 'Delete Launcher'
      } else if (this.tabValue == 2) {
        this.deleteTitle = 'Delete On prem'
      } else if (this.tabValue == 3) {
        this.deleteTitle = 'Delete Cloud'
      }

      if (this.tabValue == 0) {
        this.msg = 'Are you sure to delete the app ' + row.app_name + ' ?'
      } else if (this.tabValue == 1) {
        this.msg = 'Are you sure to delete the launcher ' + row.org_name + '?'
      } else if (this.tabValue == 2) {
	if(row.org_name == null){
          this.msg = 'Are you sure to delete the onprem ' + row.provider_name + '?'
        }else{  
	  this.msg = 'Are you sure to delete the onprem ' + row.org_name + '?'
	}
      } else if (this.tabValue == 3) {
        this.msg = 'Are you sure to delete the cloud ' + row.instance_name + '?'
      }
      this.entity = row
      this.showRemoveModal = true
    },
    cancel () {
      this.showRemoveModal = false
    },
    deleteApp (data) {
      var path, params
      if (this.tabValue == 0) {
        path = '/operator_app/'
        params = data.operator_id
      }
      if (this.tabValue == 1) {
        path = '/launcher/'
        params = data.operator_id
      }
      if (this.tabValue == 2) {
        path = '/onprem/'
        params = data.onprem_id + '/' + data.platform
      }
      if (this.tabValue == 3) {
        path = '/cloud/'
        params = data.instance_name
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + path + params)
        .then(resp => {
          loader.hide()
          if (resp && resp.body) {
            Vue.notify({ text: resp.body, type: 'success' })
          }
          this.getAllTabs()
        }, err => {
          if (err && err.body) {
            loader.hide()
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
    },
    add () {
      this.isshowgrid = false
      clearInterval(this.interval)
      if (this.tabValue == 0) {
        this.title = 'Create App'
      } else if (this.tabValue == 1) {
        this.title = 'Create Launcher'
      } else if (this.tabValue == 2) {
        this.title = 'Create On Prem'
      } else if (this.tabValue == 3) {
        this.title = 'Create Cloud'
      }
      this.app_name = ''
      this.AppNameErrors = []
      this.package_name = ''
      this.app_token = ''
      this.AppTokenErrors = []
      this.protocol = ''
      this.cdn_domain = ''
      this.port = ''
      this.brand_color = ''
      this.code_type = ''
      this.play_console_account = ''
      this.ott_url = ''
      this.cloud_server_urlErrors = []
      this.logo_file = []
      this.banner_file = []
      this.playstore_banner = []
      this.brand_logo = []
      this.json_file = []
      this.image = true;
      this.roleErrors = []
      this.PackageNameErrors = []
      this.DomainTypeErrors = []
      this.CdnDomainErrors = []
      this.PortErrors = []
      this.emailErrors = []
      this.passwordErrors = []
      this.phoneNumberErrors = []
      this.ColourErrors = []
      this.codeTypeError = []
      this.ip_domain = ''
      this.ip_domainErrors = []
      this.portErrors = []
      this.token = ''
      this.tokenErrors = []
      this.AccountErrors = ''
      this.select_version = []
      this.select_version = ''
      this.appSelectVersionErrors = []
      this.launcher_version = ''
      this.launcher_versionErrors = []
      this.launcher_video = ''
      this.instance_name = ''
      this.instance_nameErrors = []
      this.ip = ''
      this.ipErrors = []
      this.cloud_port = ''
      this.cloud_portErrors = []
      this.cloud_token = ''
      this.cloud_tokenErrors = []
      this.backup_infra_id = ''
      this.backup_infra_idErrors = []
      this.backup_db_id = ''
      this.backup_db_idErrors = []
      this.platform = ''
      this.platformErrors = []
      this.cloud_version = ''
      this.cloudVersionErrors = []
      this.organization_name = ''
      this.organization_nameErrors = []
      this.cdn_url = ''
      this.cdn_urlErrors = []
      this.time_zone = ''
      this.time_zoneErrors = []
      this.provider_name = ''
      this.ProviderNameErrors = []
      //this.operator_domain = ''
      //this.operator_domainErrors = []
      this.brandColor = ''
      this.secondary_color = ''
      this.text_color = ''
      this.platform_version = ''
      this.textsize_type = ''
      this.live = ''
      this.web_series = ''
      this.tv_shows = ''
      this.movies = ''
      this.horizontal_margin = ''
      this.corner_radius = ''
      this.mobile_horizontal = ''
      this.mobile_vertical = ''
      this.tv_horizontal = ''
      this.tv_vertical = ''
      this.border_size = ''
      this.zoom_size = ''
      this.mobile_banner_height = ''
      this.tv_banner_height = ''
      this.mobile_advertisement_height = ''
      this.launcher_type = ''
      this.LauncherTypeErrors = []
      this.launcher_logo = 'Image'
      this.previewImage = ''
      this.previewImage1 = ''
      this.launcher_video = ''

      if (this.tabValue == 0) {
        this.isshowgrid = false
        this.isshowForm = true
        this.isUpdate = false
        this.isCreate = true
        this.updatenow = false
        this.isShowDynamic = false
      } else if (this.tabValue == 1) {
        this.isshowgrid = false
        this.isshowForm = false
        this.isShowLauncher = true
        this.isLauncherCreate = true
        this.isLauncherUpdate = false
        this.isUpdate = false
        this.isCreate = false
      } else if (this.tabValue == 2) {
        this.isshowgrid = false
        this.isOnprem = true
        this.isOnpremCreate = true
        this.isOnpremUpdate = false
        this.isshowForm = false
      } else if (this.tabValue == 3) {
        this.isshowgrid = false
        this.isshowForm = false
        this.isShowCloud = true
        this.isCloudCreate = true
      }
    },
    list () {
      // this.getAllApps()
      if (this.tabValue == 0) {
        this.isshowgrid = true
        this.isshowList = false
        this.isShowList = false
        this.isshowForm = false
        this.isShowDynamic = false
      } else if (this.tabValue == 1) {
        this.isShowLauncher = false
        this.isshowgrid = true
        this.isshowForm = false
        this.isShowList = false
        this.isLauncherCreate = false
        this.isLauncherUpdate = false
      } else if (this.tabValue == 2) {
        this.isshowgrid = true
        this.isshowForm = false
        this.isShowList = false
        this.isOnprem = false
        this.onprem_update = false
        this.isOnpremUpdate = false
      } else if (this.tabValue == 3) {
        this.isshowgrid = true
        this.isshowForm = false
        this.isShowCloud = false
        this.isShowList = false
        this.isCloudUpdate = false
      }
      this.appSelectVersionErrors = []
      this.cloudVersionErrors = []
      this.getAllTabs()
      // this.isshowForm = false
      // this.isshowgrid = true
      // this.isshowlist = false
      // this.isShowList = false
      // this.isShowCloud = false
    },
    dynamic () {
      if (this.tabValue == 0) {
        this.isshowgrid = false
        this.isShowDynamic = true
      }
      this.isMid = true
      this.textsize_typeErrors = []
      this.liveErrors = []
      this.web_seriesErrors = []
      this.tv_showsErrors = []
      this.moviesErrors = []
      this.horizontal_marginErrors = []
      this.corner_radiusErrors = []
      this.mobile_horizontalErrors = []
      this.mobile_verticalErrors = []
      this.tv_horizontalErrors = []
      this.tv_verticalErrors = []
      this.mobile_banner_heightErrors = []
      this.tv_banner_heightErrors.length = []
      this.mobile_advertisement_heightErrors = []
      this.zoom_sizeErrors = []
      this.border_sizeErrors = []
    },
    search: function (term) {
      this.term = term
    },
    launchToast () {
      this.showToast(
        this.toastText,
        {
          icon: this.toastIcon,
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        },
      )
    },
  },
}
</script>

<style lang="scss">
  .title-view {
    color: $primary-black !important;
    font-size: 24px;
    text-transform: capitalize;
  }

  .cursor {
    cursor: revert;
  }

  .versionCursor {
    cursor: pointer;
    color: #0356fc;
  }

  .va-modal__inner {
    margin-left: 30px;
  }

  .imagePreviewWrapper {
    width: 80px;
    height: 80px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
  }
</style>
